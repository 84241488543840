import React from 'react';

export const HeadsetInCircleIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      preserveAspectRatio="none"
      viewBox="0 0 1060 1060"
    >
      <circle fill={props.circleColor} cx="530" cy="530" r="512" />
      <path
        fill={props.iconColor}
        d="M 494 228
            c -116 15 -198 121 -198 237
            v 172
            c 0 44 34 78 77 78
            h 31
            c 26 0 44 -21 44 -44
            v -116
            c 0 -26 -21 -46 -46 -46
            h -54
            v -43
            c 0 -93 67 -175 157 -186
            c 110 -15 203 70 203 178
            v 51
            h -59
            c -23 0 -44 21 -44 44
            v 116
            c 0 26 21 46 44 46
            h 59
            v 26
            h -159
            a 20 20 0 0 0 -21 21
            v 13
            c 0 10 8 21 21 21
            h 136
            c 44 0 78 -34 78 -77
            v -262
            c -2 -139 -126 -250 -268 -229
            z"
      />
    </svg>
  );
};
