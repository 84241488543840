import React, { PureComponent } from 'react';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/core';
import { Colors } from 'modules/app/style/colors';
import { ThreeDotsIcon } from 'modules/ui/assets/icons/ThreeDotsIcon';
import PropTypes from 'prop-types';
import { RSDropdown } from '@rosetta/rs-dropdown';
import { withHover } from 'modules/ui/containers/withHover';

const menuWidth = 150;
const buttonWidth = 32;

const DotsButtonDiv = styled.div((props) => {
  const style = {
    cursor: 'pointer',
    position: 'relative',
    width: '100%',
    height: '100%',
    zIndex: 1001
  };
  return style;
});

const ThreeDotsPositioner = styled.div({
  position: 'absolute',
  top: 4,
  left: 4,
  height: 16,
  width: 16
});

class MenuButton extends PureComponent {
  render() {
    let iconColor = Colors.UnselectedGrey;
    if (this.props.menuShowing || this.props.hovering) {
      iconColor = Colors.SelectedBlue;
    }
    return (
      <DotsButtonDiv {...this.props}>
        <ThreeDotsPositioner data-qa="ThreeDotsPositioner">
          <ThreeDotsIcon fillColor={iconColor} />
        </ThreeDotsPositioner>
      </DotsButtonDiv>
    );
  }
}

const zoomOpen = keyframes({
  '0%': {
    opacity: 0,
    transformOrigin: '100% 0%',
    transform: 'scaleX(0) scaleY(0)'
  },
  '100%': {
    opacity: 1,
    transformOrigin: '100% 0%',
    transform: 'scaleX(1) scaleY(1)'
  }
});

const _menuTopPad = 18;
const _itemHeight = 32;
const menuStyle = {
  menuTopPad: _menuTopPad,
  itemHeight: _itemHeight,
  animateMenu: true,
  listMaxHeight: 10000,
  buttonBorderWidth: 0,
  listBorderWidth: 0,

  RSDropdownDiv: styled.div({
    position: 'absolute',
    top: 16,
    right: 16,
    width: buttonWidth
  }),

  MenuButton: withHover(MenuButton),

  MenuButtonLabel: () => {
    return null;
  },
  MenuButtonArrow: () => {
    return null;
  },

  MenuList: styled.div((props) => {
    const style = {
      boxSizing: 'border-box',
      position: 'absolute',
      top: 0,
      right: 0,
      width: menuWidth,
      paddingTop: _menuTopPad,
      paddingBottom: 6,
      backgroundColor: Colors.Light,
      border: '1px solid ' + Colors.LightGrey,
      boxShadow: '3px 3px 6px ' + Colors.VeryLightGrey,
      zIndex: '1000'
    };
    if (props.animationToDo === 'showing') {
      style.animation = `${zoomOpen} 200ms ease`;
    }
    if (props.animationToDo === 'hiding') {
      style.animation = `${zoomOpen} 200ms ease reverse`;
    }
    return style;
  }),

  MenuItem: styled.div((props) => {
    const style = {
      cursor: 'pointer',
      backgroundColor: Colors.Light,
      color: Colors.Dark,
      fontSize: '16px',
      fontWeight: 'normal',
      letterSpacing: '0',
      height: _itemHeight,
      paddingTop: 3,
      paddingLeft: 24,
      paddingRight: 10,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textAlign: 'left'
    };
    if (props.highlight) {
      style.color = Colors.Light;
      style.backgroundColor = Colors.SelectedBlue;
    }
    if (props.selected) {
      style.color = Colors.Light;
      style.cursor = 'default';
      style.backgroundColor = Colors.SelectedBlue;
    }
    return style;
  })
};

//***************************************************************************************
export class RemoveCourseButton extends PureComponent {
  static propTypes = {
    lionTranslate: PropTypes.func.isRequired,
    removeCourseHandler: PropTypes.func.isRequired
  };

  render() {
    const menuItemsList = [
      {
        label: this.props.lionTranslate('_add_courses_remove'),
        value: 'remove'
      }
    ];

    return (
      <RSDropdown
        dataQa="RemoveCourseButton"
        buttonText=""
        buttonTextIsStatic={true}
        itemsList={menuItemsList}
        itemDisplayProperty="label"
        itemKeyProperty="value"
        onSelectionHandler={this.props.removeCourseHandler}
        selectedKey={'nothing'}
        menuTabIndex={-1}
        menuStyle={menuStyle}
      />
    );
  }
}
