import React, { PureComponent } from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';

//  withHover handles getting hover state to props,
//    also handles active state
//
//  it creates a "hovering" prop the wrapped component can use to set UI state
//   and also an "active" prop for the same reason
//
//  if you hand in a "clickHandler" prop, it will execute that on onClick
//   so NOTE that if you have such a prop on the component that is wrapped, don't call onClick
//   on clickHandler there, because that will make it happen twice

// inline-block so it is as big as its contents
const HoverableDiv = styled.div((props) => {
  const style = {
    label: 'HoverableDiv',
    display: 'inline-block',
    verticalAlign: 'top'
  };
  if (props.disabled) {
    style.pointerEvents = 'none';
  }
  if (props.hoverableWidth) {
    style.width = props.hoverableWidth;
  }
  if (props.hoverableHeight) {
    style.height = props.hoverableHeight;
  }
  return style;
});

export function withHover(WrappedComponent) {
  return class extends PureComponent {
    static propTypes = {
      clickHandler: PropTypes.func,
      hoverableWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      hoverableHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    };

    constructor(props) {
      super(props);
      this.state = {
        hovering: false,
        active: false
      };
      this.clickHandler = props.clickHandler ? props.clickHandler : () => {};
    }

    handleAnyMouseUp = () => {
      this.setState({ active: false });
    };

    componentWillUnmount() {
      document.removeEventListener('mouseup', this.handleAnyMouseUp);
    }

    handleMouseEnter = () => {
      this.setState({ hovering: true });
    };
    handleMouseLeave = () => {
      this.setState({ hovering: false });
    };
    handleMouseDown = () => {
      if (!this.state.active) {
        this.setState({ active: true });
        document.addEventListener('mouseup', this.handleAnyMouseUp, { once: true });
      }
    };

    render() {
      return (
        <HoverableDiv
          data-qa={this.props.dataQa}
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave}
          onMouseDown={this.handleMouseDown}
          onClick={this.clickHandler}
          disabled={this.props.disabled}
          hoverableWidth={this.props.hoverableWidth}
          hoverableHeight={this.props.hoverableHeight}
        >
          <WrappedComponent
            hovering={!this.props.disabled && this.state.hovering}
            active={!this.props.disabled && this.state.active}
            {...this.props}
          />
        </HoverableDiv>
      );
    }
  };
}
