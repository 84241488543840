import {
  initializeGaiaData,
  createApolloClient,
  AssignedCoursesAndProgressQuery,
  amplitudeHelper,
  courseSelectors,
  stepModel,
  stepCardModel,
  pronunciationModel
} from '@rosetta/gaia-data';
import { initializeGaiaProgress, stepStatusActions } from '@rosetta/gaia-progress';
import { startInteraction, TIMING_STRATEGIES } from '@rosetta/eve-client';
import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from 'react-apollo';
import { Provider as ReduxProvider } from 'react-redux';
import { createBrowserHistory } from 'history';

import { FBGoogleTagManager } from './google/GoogleTagManager';
import { logger } from 'modules/app/utils/logger';
import { addToDevContext } from 'modules/dev/consoleCommands';
import { GaiaRouter } from '../../routes';
import { getConfig } from './configs/config-utils';
import { appActions, appSelectors } from './redux/app-reducer';
import configureStore, { setReduxStore } from './store';
import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { version } from '../../../package.json';

import './style/gaia-web-body-style.css';
import './style/font.css';

import './init';

const sampleRate =
  getConfig().env === 'prod' ? 10 : getConfig().env === 'dev' || getConfig().env === 'local' ? 50 : 10;

datadogLogs.init({
  clientToken: 'pubcd4154836b9b77b115703beb0ce24033',
  site: 'datadoghq.com',
  forwardErrorsToLogs: true,
  sampleRate: sampleRate,
  env: getConfig().env,
  version: version
});

datadogRum.init({
  applicationId: '5f306009-a0e8-47ad-abcd-b71aa94701a2',
  clientToken: 'pubacbcafcbfa8a0ec36db0e8d0a135dab9',
  site: 'datadoghq.com',
  service: 'fluency-builder',
  env: getConfig().env,
  version: version,
  sampleRate: sampleRate,
  trackInteractions: true
});

startInteraction('application-startup', TIMING_STRATEGIES.FROM_PAGE_START);

const history = createBrowserHistory();
const store = configureStore(history);

const getWindow = () => {
  return window;
};

function learningActivityInterrupted() {
  // Prevent overhead usage messages from being emitted while performing assessments.
  if (!courseSelectors.getIsAssessment(store.getState())) {
    store.dispatch(stepStatusActions.learningActivityInterrupted());
  }
}
function learningActivityResumed() {
  if (!courseSelectors.getIsAssessment(store.getState())) {
    store.dispatch(stepStatusActions.learningActivityResumed());
  }
}

setReduxStore(store);
logger.reduxStore = store;
addToDevContext('store', store);

// gaiaData needs some standard app things
const gdAppActions = {
  setAppFontForLanguage: appActions.setAppFontForLanguage,
  installLionLanguage: appActions.installLionLanguage
};
const gdAppSelectors = {
  appFont: appSelectors.appFont
};
initializeGaiaData({
  config: getConfig(),
  errorHandler: logger.sendToAirbrake,
  logger: logger,
  appActions: gdAppActions,
  appSelectors: gdAppSelectors,
  reduxStore: store
});

const apolloClient = createApolloClient(store);
addToDevContext('apolloClient', apolloClient);

initializeGaiaProgress({
  config: getConfig(),
  errorHandler: logger.sendToAirbrake,
  gaiaApolloClient: apolloClient,
  logger,
  assignedCoursesAndProgressQuery: AssignedCoursesAndProgressQuery,
  enhanceProgressMessageWithAnalytics: (message) => {
    // ff are because message does double duty as analysisEvent
    message[amplitudeHelper.analyticEventPropNames.appName] = getConfig().appName;
    message[amplitudeHelper.analyticEventPropNames.appVersion] = getConfig().appVersion;
    message[amplitudeHelper.analyticEventPropNames.sreVersion] = getConfig().sreVersion;
    message[amplitudeHelper.analyticEventPropNames.screenWidth] = window.innerWidth;
    message[amplitudeHelper.analyticEventPropNames.screenHeight] = window.innerHeight;
  },
  userAgent: navigator.userAgent,
  reduxStore: store,
  courseSelectors: courseSelectors,
  stepModel: stepModel,
  stepCardModel: stepCardModel,
  pronunciationModel: pronunciationModel
});

getWindow().onfocus = learningActivityResumed;
getWindow().onblur = learningActivityInterrupted;

ReactDOM.render(
  <ApolloProvider client={apolloClient}>
    <ReduxProvider store={store}>
      <FBGoogleTagManager />
      <GaiaRouter store={store} history={history} />
    </ReduxProvider>
  </ApolloProvider>,
  document.getElementById('root')
);
