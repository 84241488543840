import { v4 as uuidV4 } from 'uuid';
import { stopPollingActiveStatus } from '../redux/app-sagas';

const APP_INSTANCE_COOKIES = 'APP_INSTANCE_COOKIES';
const APP_INSTANCE_ID = 'APP_INSTANCE_ID';

const getActiveInstancesIds = () => {
  const activeInstancesIdsStr = localStorage.getItem(APP_INSTANCE_COOKIES);
  const activeInstancesIds = activeInstancesIdsStr ? JSON.parse(activeInstancesIdsStr) : [];
  return activeInstancesIds;
};

const saveActiveInstanceIds = (instanceIdsArray) => {
  if (instanceIdsArray.length > 0) {
    localStorage.setItem(APP_INSTANCE_COOKIES, JSON.stringify(instanceIdsArray));
  } else {
    localStorage.removeItem(APP_INSTANCE_COOKIES);
  }
};

const removeThisInstanceId = (e) => {
  stopPollingActiveStatus();
  const instanceId = sessionStorage.getItem(APP_INSTANCE_ID);
  if (instanceId) {
    const activeInstancesIds = getActiveInstancesIds();
    const newActiveInstancesIds = activeInstancesIds.filter((id) => id !== instanceId);
    saveActiveInstanceIds(newActiveInstancesIds);
  }
};

export const initForMultipleInstances = () => {
  // first recover our stored instance ID
  let instanceId = sessionStorage.getItem(APP_INSTANCE_ID);
  if (!instanceId) {
    instanceId = uuidV4();
    sessionStorage.setItem(APP_INSTANCE_ID, instanceId);
  }
  // check localstore for other active gaia-web tabs
  const activeInstancesIds = getActiveInstancesIds();
  if (!activeInstancesIds.includes(instanceId)) {
    activeInstancesIds.push(instanceId);
    saveActiveInstanceIds(activeInstancesIds);
  }
  window.addEventListener('beforeunload', removeThisInstanceId);
};

export const removeAllInstanceIds = () => {
  localStorage.removeItem(APP_INSTANCE_COOKIES);
};

export const isThisInstanceActive = () => {
  const instanceId = sessionStorage.getItem(APP_INSTANCE_ID);
  const activeInstancesIds = getActiveInstancesIds();
  return activeInstancesIds.includes(instanceId);
};

export const areThereOtherActiveInstances = () => {
  const instanceId = sessionStorage.getItem(APP_INSTANCE_ID);
  const activeInstancesIds = getActiveInstancesIds();
  const otherInstance = activeInstancesIds.find((anId) => anId !== instanceId);
  return !!otherInstance;
};
