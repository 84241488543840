import React from 'react';
import { Route, Switch } from 'react-router';
import { ConnectedRouter } from 'connected-react-router';

import { AppPage } from 'modules/app/pages/App';
import { ErrorPageBadPath } from 'modules/app/pages/ErrorPageBadPath';
import { MyCoursesPage } from 'modules/course/pages/MyCoursesPage';
import { LogoutPage } from 'modules/app/pages/LogoutPage';
import { NotAuthenticatedPage } from 'modules/app/pages/NotAuthenticatedPage';
import { userSelectors } from '@rosetta/gaia-data';
import {
  DevLoginPage,
  AdminPage,
  CoursePage,
  GetTheAppPage,
  SequencePage,
  AssessmentPage,
  AddCoursesPage
} from 'modules/app/LazyPages';

const PrivateRoute = ({ component: Component, store, ...rest }) => {
  if (userSelectors.isLoggedIn(store.getState())) {
    return (
      <Route
        {...rest}
        render={({ match, ...otherProps }) => {
          return <Component {...otherProps} url={match.url} {...match.params} />;
        }}
      />
    );
  } else {
    return (
      <Route
        {...rest}
        render={(props) => {
          return <NotAuthenticatedPage {...props} />;
        }}
      />
    );
  }
};

export const GaiaRouter = ({ store, history }) => {
  return (
    <ConnectedRouter history={history}>
      <AppPage>
        <Switch>
          <PrivateRoute exact path="/logout" component={LogoutPage} store={store} />
          <Route exact path="/dev_only_login" component={DevLoginPage} />
          <PrivateRoute exact path="/course/:courseSlug" component={CoursePage} store={store} />
          <PrivateRoute
            path="/course/:courseSlug/:sequenceSlug/:activitySlug?/:stepSlug?"
            component={SequencePage}
            store={store}
          />
          <PrivateRoute exact path="/assessments/:assessmentId/:stepIndex?" component={AssessmentPage} store={store} />
          <PrivateRoute exact path="/courses" component={AddCoursesPage} store={store} />
          <PrivateRoute exact path="/admin" component={AdminPage} store={store} />
          <PrivateRoute exact path="/mobile" component={GetTheAppPage} store={store} />
          <PrivateRoute exact path="/" component={MyCoursesPage} store={store} />
          <Route component={ErrorPageBadPath} />
        </Switch>
      </AppPage>
    </ConnectedRouter>
  );
};
