import React from 'react';

export const ThreeDotsIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      preserveAspectRatio="none"
      viewBox="0 0 1060 1060"
      fill={props.fillColor}
    >
      <circle cx="160" cy="530" r="130" />
      <circle cx="530" cy="530" r="130" />
      <circle cx="900" cy="530" r="130" />
    </svg>
  );
};
