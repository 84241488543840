import React, { PureComponent } from 'react';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/core';
import { Colors } from 'modules/app/style/colors';
import { withHover } from 'modules/ui/containers/withHover';
import { PersonInCircleIcon } from 'modules/ui/assets/icons/PersonInCircleIcon';

const UserMenuIconDiv = styled.div((props) => {
  const style = {
    position: 'absolute',
    left: 8,
    top: 12,
    height: 36,
    width: 36
  };
  return style;
});

const ButtonDiv = styled.div((props) => {
  const style = {
    cursor: 'pointer',
    display: 'inline-block',
    verticalAlign: 'top',
    position: 'relative',
    minWidth: 120,
    maxWidth: 400,
    height: 60,
    paddingLeft: 57,
    paddingRight: 26
  };
  return style;
});

const UsernameText = styled.div((props) => {
  const style = {
    fontSize: '16px',
    lineHeight: '20px',
    marginTop: 12,
    color: Colors.Dark,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textAlign: 'left'
  };
  if (props.menuShowing || props.hovering) {
    style.color = Colors.SelectedBlue;
  }
  return style;
});

const TargetLanguageText = styled.div((props) => {
  const style = {
    fontSize: '14px',
    lineHeight: '18px',
    color: Colors.UnselectedGrey,
    marginLeft: 1,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textAlign: 'left'
  };
  return style;
});

class UserMenuButton extends PureComponent {
  render() {
    let personOpacity = 0.4;
    let circleColor = Colors.UserIconColor;
    if (this.props.hovering || this.props.menuShowing) {
      circleColor = Colors.SelectedBlue;
      personOpacity = 1.0;
    }
    return (
      <ButtonDiv {...this.props}>
        <UserMenuIconDiv>
          <PersonInCircleIcon circleColor={circleColor} personColor={Colors.Light} personOpacity={personOpacity} />
        </UserMenuIconDiv>
        <UsernameText menuShowing={this.props.menuShowing} hovering={this.props.hovering}>
          {this.props.userName}
        </UsernameText>
        <TargetLanguageText>{this.props.targetLanguage}</TargetLanguageText>
      </ButtonDiv>
    );
  }
}

const dropOpen = keyframes({
  '0%': {
    opacity: 0,
    transformOrigin: '0% 0%',
    transform: 'scaleY(0)'
  },
  '100%': {
    opacity: 1,
    transformOrigin: '0% 0%',
    transform: 'scaleY(1)'
  }
});

const _menuTopPad = 0;
const _itemHeight = 44;
export const userMenuStyle = {
  menuTopPad: _menuTopPad,
  itemHeight: _itemHeight,
  animateMenu: true,
  listMaxHeight: 10000,
  buttonBorderWidth: 0,
  listBorderWidth: 0,

  RSDropdownDiv: styled.div((props) => {
    const style = {
      position: 'relative',
      height: 60,
      display: 'inline-block'
    };
    return style;
  }),

  MenuButton: withHover(UserMenuButton),

  MenuButtonLabel: () => {
    return null;
  },

  MenuButtonArrow: () => {
    return null;
  },

  MenuList: styled.div((props) => {
    const style = {
      position: 'absolute',
      top: props.listTop,
      right: 4,
      minWidth: 180,
      paddingTop: _menuTopPad,
      paddingBottom: 0,
      backgroundColor: Colors.Light,
      boxShadow: '3px 3px 6px rgba(0,0,0,0.15)',
      zIndex: '1000'
    };
    if (props.animationToDo === 'showing') {
      style.animation = `${dropOpen} 300ms ease`;
    }
    if (props.animationToDo === 'hiding') {
      style.animation = `${dropOpen} 300ms ease reverse`;
    }
    return style;
  }),

  MenuItem: styled.div((props) => {
    const topPad = 11;
    const style = {
      cursor: 'pointer',
      backgroundColor: Colors.Light,
      color: Colors.Dark,
      fontSize: '16px',
      fontWeight: 'normal',
      letterSpacing: '0',
      height: _itemHeight,
      paddingTop: topPad,
      paddingLeft: 21,
      paddingRight: 10,
      borderBottom: '1px solid ' + Colors.LightGrey,
      ':last-of-type': {
        borderBottom: 'none',
        paddingTop: topPad - 1
      },
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textAlign: 'left'
    };
    if (props.highlight) {
      style.color = Colors.Light;
      style.backgroundColor = Colors.SelectedBlue;
    }
    if (props.selected) {
      style.color = Colors.Light;
      style.cursor = 'default';
      style.backgroundColor = Colors.SelectedBlue;
    }
    return style;
  })
};
