const IOS_ID = 'id1440193976';
const ANDROID_ID = 'com.rosettastone.gaia';

const isIos = () => /(iPad|iPhone|iPod)/.test(navigator.userAgent);
const isAndroid = () => /android/i.test(navigator.userAgent);
// const isKindle = () => /\bSilk\b/.test(navigator.userAgent);
export const isMobile = () => isIos() || isAndroid(); /*|| isKindle()*/

function getLocale(locale) {
  switch (locale) {
    case 'de-DE':
      return 'de';
    case 'es-419':
      return 'es';
    case 'fr-FR':
      return 'fr';
    case 'it-IT':
      return 'it';
    case 'ja-JP':
      return 'jp';
    case 'ko-KR':
      return 'kr';
    case 'pt-BR':
      return 'br';
    case 'zn-CN':
      return 'cn';
    default:
      return 'us';
  }
}

function getAppStoreLink(locale) {
  if (isIos()) {
    return `https://apps.apple.com/${getLocale(locale)}/app/rosetta-stone-fluency-builder/${IOS_ID}?mt=8`;
  } else {
    return `https://play.google.com/store/apps/details?id=${ANDROID_ID}&hl=${locale.split('-')[0]}`;
    // return `intent://rosettastone/home#{query_string}#Intent;scheme=learnlanguages;package=${ANDROID_ID};end`
  }
}

export function goToAppStore(locale) {
  window.location = getAppStoreLink(locale);
}
