import { combineReducers } from 'redux';
import {
  abReducer,
  courseReducer,
  sequenceReducer,
  tutoringReducer,
  userReducer
} from '@rosetta/gaia-data';
import { progressReducer, stepStatusReducer } from '@rosetta/gaia-progress';
import { mediaReducer } from '@rosetta/gaia-media';
import { lionReducer } from '@rosetta/react-lion';
import { sreReducer } from '@rosetta/react-sre';
import { connectRouter } from 'connected-react-router';
import { appReducer } from 'modules/app/redux/app-reducer';
import { keyboardReducer } from 'modules/keyboard/redux/keyboard-reducer';
import { speechReducer } from 'modules/speech/redux/speech-reducer';

export const rootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    ab: abReducer,
    app: appReducer,
    course: courseReducer,
    keyboard: keyboardReducer,
    lion: lionReducer,
    media: mediaReducer,
    progress: progressReducer,
    sequence: sequenceReducer,
    speech: speechReducer,
    sre: sreReducer,
    stepStatus: stepStatusReducer,
    tutoring: tutoringReducer,
    user: userReducer
  });
