import styled from '@emotion/styled';
import { Colors } from 'modules/app/style/colors';

export const ButtonSecondary = styled.div((props) => {
  const style = {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '6px',
    backgroundColor: Colors.Light,
    borderStyle: 'solid',
    borderColor: Colors.PrimaryDark,
    color: Colors.PrimaryDark,
    height: 50,
    maxWidth: '100%',
    letterSpacing: 'normal',
    fontSize: 16,
    paddingLeft: 54,
    paddingRight: 54,
    fontWeight: 500,
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    outline: 'none',
    ':hover': {
      backgroundColor: Colors.SecondaryHoverGrey
    },
    ':hover:active': {
      color: Colors.DarkShadeOne,
      borderColor: Colors.DarkShadeOne
    }
  };
  if (props.disabled) {
    style.border = 'none';
    style.color = Colors.DarkShadeTwo;
    style.backgroundColor = Colors.LightGrey;
    style.pointerEvents = 'none';
  }
  return style;
});
