import { registerDevFunction } from 'modules/dev/consoleCommands';
import { courseGraphQL, courseActions, userSelectors } from '@rosetta/gaia-data';
import { logger } from 'modules/app/utils/logger';

registerDevFunction('addCourse', (context) => (courseId, cefr) => {
  const { store, apolloClient } = context;
  const userId = userSelectors.getUserId(store.getState());
  const locale = userSelectors.getLearningLanguage(store.getState());
  apolloClient.mutate({
    mutation: courseGraphQL.AssignCourseMutation,
    variables: { userId, courseId, cefr },
    update: (proxy, { data: { assignCourse } }) => {
      try {
        const data = proxy.readQuery({
          query: courseGraphQL.AssignedCoursesAndProgressQuery,
          variables: {
            cefr,
            locale
          }
        });
        data.assignedCourses = assignCourse;
        proxy.writeQuery({
          query: courseGraphQL.AssignedCoursesAndProgressQuery,
          variables: {
            cefr,
            locale
          },
          data
        });
      } catch (error) {
        logger.error('Error updating Apollo cache: %O', error);
      }
    }
  });
});

registerDevFunction('removeCourse', (context) => (courseId) => {
  const { store, apolloClient } = context;
  const userId = userSelectors.getUserId(store.getState());
  const locale = userSelectors.getLearningLanguage(store.getState());
  const cefr = userSelectors.getCefr(store.getState());
  apolloClient.mutate({
    mutation: courseGraphQL.UnassignCourseMutation,
    variables: { userId, courseId, locale },
    update: (apolloCache, results) => {
      // deleteCourses (which actually unassigns, not deletes, the course)
      //  returns an array of all the now-assigned courses
      const newAssignedCourses = results.data.deleteCourses;
      try {
        const data = apolloCache.readQuery({
          query: courseGraphQL.AssignedCoursesAndProgressQuery,
          variables: {
            cefr: cefr,
            locale: locale
          }
        });
        data.assignedCourses = newAssignedCourses;
        apolloCache.writeQuery({
          query: courseGraphQL.AssignedCoursesAndProgressQuery,
          variables: {
            cefr: cefr,
            locale: locale
          },
          data
        });
        courseActions.setAssignedCoursesAndProgress(data);
      } catch (error) {
        logger.error('Error updating Apollo cache: %O', error);
      }
    }
  });
});
