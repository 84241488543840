import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { ContentImage } from '@rosetta/gaia-media';
import { Colors } from 'modules/app/style/colors';
import { withLionTranslate } from 'modules/localization/containers/withLionTranslate';
import { courseModel, localizationModel } from '@rosetta/gaia-data';
import { ButtonPrimary } from 'modules/ui/components/ButtonPrimary';
import { ButtonSecondary } from 'modules/ui/components/ButtonSecondary';
import {
  COURSE_DISPLAYER_HEIGHT,
  COURSE_DISPLAYER_WIDTH,
  CourseDisplayerImageDiv,
  CourseDisplayerInfoDiv,
  CourseDisplayerTitleText,
  CourseDisplayerTopicText
} from './CourseDisplayerStyles';
import { LearningProgressBar } from './LearningProgressBar';
import { RemoveCourseButton } from './RemoveCourseButton';

export const AssignedCourseDiv = styled.div((props) => {
  const style = {
    position: 'relative',
    width: COURSE_DISPLAYER_WIDTH,
    height: COURSE_DISPLAYER_HEIGHT,
    overflow: 'hidden',
    borderRadius: '6px',
    marginTop: 20,
    marginBottom: 4,
    ':first-of-type': {
      marginTop: 0
    },
    ':last-of-type': {
      marginBottom: 0
    }
  };
  return style;
});

const ButtonPositioner = styled.div({
  position: 'absolute',
  right: 24,
  top: 119,
  width: 151
});

const LessonsCompletedText = styled.div({
  position: 'absolute',
  top: 111,
  left: 26,
  fontSize: '18px',
  fontWeight: 400,
  color: Colors.UnselectedGrey
});

const ProgressBarPositioner = styled.div({
  position: 'absolute',
  top: 152,
  left: 24,
  height: 6,
  width: 328
});

//***************************************************************************************
class AssignedCourseBase extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  static propTypes = {
    course: PropTypes.object.isRequired,
    languages: PropTypes.object.isRequired,
    progressInfo: PropTypes.object.isRequired,
    canRemoveCourse: PropTypes.bool.isRequired,
    removeCourseHandler: PropTypes.func.isRequired,
    launchCourseHandler: PropTypes.func.isRequired
  };

  handleRemoveCourseClick = (event) => {
    this.props.removeCourseHandler(this.props.course);
  };

  handleLaunchCourseClick = (event) => {
    this.props.launchCourseHandler(this.props.course);
  };

  render() {
    if (!this.props.course) {
      return null;
    }

    const lessonsCount = this.props.course.sequences.length;
    const oneLesson = this.props.lionTranslate('_lessons_count_singular');
    const multipleLessons = this.props.lionTranslate('_lessons_count_plural', { number: lessonsCount });
    const lessonsPassed = this.props.progressInfo.lessonsPassed;

    let lessonText;
    if (lessonsPassed === 0) {
      lessonText = lessonsCount === 1 ? oneLesson : multipleLessons;
    } else if (lessonsPassed === lessonsCount) {
      lessonText = this.props.lionTranslate('_home_all_lessons_completed', { number: lessonsCount });
    } else {
      lessonText = this.props.lionTranslate('_home_lessons_completed', {
        completed: lessonsPassed,
        total: lessonsCount
      });
    }

    const topic = this.props.course.topics[0];
    const localizations = topic ? topic.localizations : [];
    const baseTopicName = localizationModel.getLocalizedContentText(localizations, this.props.languages) || '';
    const topicName = baseTopicName.toUpperCase();
    const courseImages = courseModel.getCourseImages(this.props.course);

    let launchText;
    if (!this.props.progressInfo.isCourseStarted) {
      launchText = this.props.lionTranslate('_home_start_course');
    } else if (this.props.progressInfo.isCourseCompleted) {
      launchText = this.props.lionTranslate('_home_review_course');
    } else {
      launchText = this.props.lionTranslate('_home_resume_course');
    }

    let launchButton;
    if (this.props.progressInfo.isCourseStarted && !this.props.progressInfo.isCourseCompleted) {
      // different kind of button for "resuming"
      launchButton = (
        <ButtonPositioner>
          <ButtonPrimary data-qa="LaunchCourseButton" onClick={this.handleLaunchCourseClick}>
            {launchText}
          </ButtonPrimary>
        </ButtonPositioner>
      );
    } else {
      launchButton = (
        <ButtonPositioner>
          <ButtonSecondary data-qa="LaunchCourseButton" onClick={this.handleLaunchCourseClick}>
            {launchText}
          </ButtonSecondary>
        </ButtonPositioner>
      );
    }

    let removeCourseButton = null;
    // can't remove a course once you have started it
    if (this.props.canRemoveCourse && !this.props.progressInfo.isCourseStarted) {
      removeCourseButton = (
        <RemoveCourseButton
          lionTranslate={this.props.lionTranslate}
          removeCourseHandler={this.handleRemoveCourseClick}
        />
      );
    }

    // note that CourseDisplayerInfoDiv is situated *under* CourseDisplayerImageDiv
    //  to make border corners look right
    const topicColor = topic ? topic.color : 'black';
    return (
      <AssignedCourseDiv data-qa="CourseDisplayerDiv">
        <CourseDisplayerInfoDiv>
          {removeCourseButton}
          <CourseDisplayerTopicText data-qa="CourseDisplayerTopicText" topicColor={topicColor}>
            {topicName}
          </CourseDisplayerTopicText>
          <CourseDisplayerTitleText data-qa="CourseDisplayerTitleText">
            {this.props.course.title}
          </CourseDisplayerTitleText>
          <LessonsCompletedText data-qa="CourseDisplayerNumberOfLessons">{lessonText}</LessonsCompletedText>
          <ProgressBarPositioner data-qa="CourseDisplayerProgressBar">
            <LearningProgressBar
              bgColor={Colors.VeryLightGrey}
              isCourseBar={true}
              isRounded={true}
              percentComplete={this.props.progressInfo.percentLessonsPassed}
              showCompletedSuccessfully={this.props.progressInfo.isCourseCompleted}
              showFailing={false}
            />
          </ProgressBarPositioner>
          {launchButton}
        </CourseDisplayerInfoDiv>
        <CourseDisplayerImageDiv>
          <ContentImage autoFill images={courseImages} />
        </CourseDisplayerImageDiv>
      </AssignedCourseDiv>
    );
  }
}

export const AssignedCourse = withLionTranslate(AssignedCourseBase);
