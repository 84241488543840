import React from 'react';
import { compose } from 'react-apollo';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { RSDropdown } from '@rosetta/rs-dropdown';
import { getConfig } from 'modules/app/configs/config-utils';
import { stopPollingActiveStatus } from 'modules/app/redux/app-sagas';
import { withLionTranslate } from 'modules/localization/containers/withLionTranslate';
import { userSelectors } from '@rosetta/gaia-data';

const getTargetLanguageLocalizationKey = (languageCode) => {
  return '_language_' + languageCode;
};

//***************************************************************************************
class UserMenuBase extends RSDropdown {
  handleItemClick = (itemNdx) => {
    this.closeMenu();
    const selectedItem = this.props.itemsList[itemNdx];
    this.setState({ selectedItemNdx: itemNdx, highlightedItemNdx: itemNdx });
    if (selectedItem.value === 'profile') {
      const launchpadProfileUrl = getConfig().profileUrl;
      stopPollingActiveStatus();
      window.location = launchpadProfileUrl;
    } else if (selectedItem.value === 'logout') {
      this.props.history.push('/logout');
    }
  };

  // ****** render
  //  copied and overrode from base class so we could hand the buttons the two extra props we need,
  ///  localize the menu items here, and not bother with the button text and arrow
  render() {
    let menuWidget;
    if (this.state.showMenu || this.state.animatingMenu) {
      let animationToDo = '';
      if (this.state.animatingMenu) {
        animationToDo = this.state.showMenu ? 'showing' : 'hiding';
      }
      const menuItemsArray = this.props.itemsList.map((listItem, itemNdx) => {
        return (
          <this.props.menuStyle.MenuItem
            data-qa={'MenuItem_' + itemNdx}
            key={listItem[this.props.itemKeyProperty]}
            highlight={itemNdx === this.state.highlightedItemNdx}
            selected={itemNdx === this.state.selectedItemNdx}
            onClick={() => this.handleItemClick(itemNdx)}
            onMouseEnter={() => this.handleItemRollover(itemNdx)}
            onMouseLeave={() => this.handleItemRollout(itemNdx)}
            isRtl={!!this.props.isRtl}
            disabled={listItem.disabled}
          >
            {this.props.lionTranslate(listItem[this.props.itemDisplayProperty])}
          </this.props.menuStyle.MenuItem>
        );
      });
      menuWidget = (
        <this.props.menuStyle.MenuList
          data-qa="MenuList"
          listTop={this.state.dropTop}
          listLeft={!this.props.drawInBody ? -this.props.menuStyle.buttonBorderWidth : this.state.bounds.left}
          menuWidth={this.state.bounds.width}
          onAnimationEnd={this.handleMenuAnimationEnd}
          animationToDo={animationToDo}
          isRtl={!!this.props.isRtl}
        >
          {menuItemsArray}
        </this.props.menuStyle.MenuList>
      );
    }
    const targetLanguage = this.props.lionTranslate(getTargetLanguageLocalizationKey(this.props.targetLanguageCode));

    return (
      <this.props.menuStyle.RSDropdownDiv
        data-qa="UserMenu"
        ref={this.dropdownRef}
        tabIndex={this.props.menuTabIndex}
        onKeyPress={this.handleKeyPress}
        onKeyDown={this.handleKeyDown}
        menuDisabled={!!this.props.menuDisabled}
      >
        <this.props.menuStyle.MenuButton
          data-qa="MenuButton"
          ref={this.buttonRef}
          menuShowing={this.state.showMenu}
          onClick={this.handleMenuButtonClick}
          onMouseEnter={this.handleMenuButtonRollover}
          onMouseLeave={this.handleMenuButtonRollout}
          menuDisabled={!!this.props.menuDisabled}
          userName={this.props.userFirstName + ' ' + this.props.userLastName}
          targetLanguage={targetLanguage}
        />
        {menuWidget}
      </this.props.menuStyle.RSDropdownDiv>
    );
  }
}

const mapStateToProps = (state) => ({
  userFirstName: userSelectors.getUserFirstName(state),
  userLastName: userSelectors.getUserLastName(state),
  targetLanguageCode: userSelectors.getLearningLanguage(state)
});

export const UserMenu = compose(
  withLionTranslate,
  withRouter,
  connect(mapStateToProps)
)(UserMenuBase);
