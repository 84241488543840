export const getLocalConfig = () => {
  return {
    serverUrl: process.env.REACT_APP_SERVER_URL || 'http://localhost:4000',
    loginUrl: !!process.env.REACT_APP_USE_DEV_LOGIN ? '/dev_only_login' : 'http://localhost:4021/#/login',
    logoutUrl: !!process.env.REACT_APP_USE_DEV_LOGIN ? '/dev_only_login' : 'http://localhost:4021/#/logout',
    redirectParam: '?uri=',
    profileUrl: 'http://localhost:4021/#/preferences',
    localhost: true,
    errbitUrl: undefined, //Leave blank so that local error won't be sent to errbit
    errbitApi: '5bd9fe0c5314026894787cf83ffb1c66',
    brazeApiKey: 'a6500576-763f-4b08-804f-9c1a1d7f9031',
    amplitudeKey: 'noMoreAmplitude',
    env: 'local',
    appName: 'Fluency Builder'
  };
};
