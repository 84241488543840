import styled from '@emotion/styled';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
  SREBrowserBlockModal,
  SRECalibrateHelp,
  SRECalibrateWindow,
  sreActions,
  sreSelectors
} from '@rosetta/react-sre';
import { stepStatusActions } from '@rosetta/gaia-progress';
import { modalZIndex } from 'modules/app/constants';
import { Colors } from 'modules/app/style/colors';

const ModalBackdropDiv = styled.div((props) => {
  const style = {
    backgroundColor: Colors.ModalBackgroundColor,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    position: 'fixed',
    zIndex: modalZIndex
  };
  return style;
});

/*
  Adding a modal? First put a key in modalPriority that determines the priority of modals
  if more than one could be displayed at once.
  Then add an entry to modals to describe it.
*/

const modalPriority = ['browserBlockMicModal', 'srehelp', 'sre'];

/**
 * Definitions of our modals
 *
 * selector: A selector to determine if it should be visible.
 * backdrop: Should we put a backdrop that obscures content behind it?
 * allowNavbar: Should that backdrop let us click the bottom navbar?
 * component: Function that takes props and returns the component
 *
 */
const modalDefs = {
  browserBlockMicModal: {
    selector: sreSelectors.isBrowserMicBlockModalVisible,
    component: (props) => (
      <ModalBackdropDiv>
        <SREBrowserBlockModal {...props} userAcknowledgesBrowserMicBlock={sreActions.userAcknowledgesBrowserMicBlock} />
      </ModalBackdropDiv>
    ),
    closeAction: sreActions.showBrowserMicBlockModal(false)
  },
  srehelp: {
    type: 'help',
    selector: sreSelectors.isSREHelpOpen,
    backdrop: false,
    component: (props) => <SRECalibrateHelp showNavbar={false} />,
    closeAction: sreActions.closeCalibrateHelp()
  },
  sre: {
    type: 'calibrate',
    selector: sreSelectors.sreCalibrating,
    backdrop: true,
    component: (props) => (
      <SRECalibrateWindow hideSkipCalibrationLink={true} hideAllCancelOptions={true} showNavbar={false} />
    ),
    closeAction: () => {
      stepStatusActions.micCalibrationFinished();
      sreActions.closeCalibrateWindow();
    }
  }
};

if (Object.keys(modalDefs).length !== modalPriority.length) {
  console.error('modals and modalPriority are different lengths, you missed something.');
}

class ModalsBase extends PureComponent {
  render() {
    const activeModal = this.props.activeModal;
    if (!activeModal) {
      return null;
    }
    return <div>{activeModal.component(this.props)}</div>;
  }
}

const getActiveModal = (state) => {
  const key = modalPriority.find((key) => modalDefs[key].selector(state));
  return key && modalDefs[key];
};

const mapStateToProps = (state) => ({
  activeModal: getActiveModal(state)
});

export const Modals = connect(mapStateToProps)(ModalsBase);
