import React from 'react';

export const ArrowForwardIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      preserveAspectRatio="none"
      viewBox="0 0 1060 1060"
    >
      <path
        fill={props.fillColor}
        stroke={props.strokeColor}
        strokeWidth={props.strokeWidth}
        d="M20 590 h 778.88 l -357.76 357.76 90.88 90.24 512-512 -512-512 -90.24 90.24 357.12 357.76 h-778.88z"
      />
    </svg>
  );
};
