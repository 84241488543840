import React, { PureComponent } from 'react';
import { compose } from 'react-apollo';
import { connect } from 'react-redux';
import getSlug from 'speakingurl';
import styled from '@emotion/styled';
import { endInteraction } from '@rosetta/eve-client';
import { amplitudeHelper, brazeHelper, courseActions, courseSelectors, userSelectors } from '@rosetta/gaia-data';
import { progressSelectors } from '@rosetta/gaia-progress';
import { withDefaultShell } from 'modules/app/containers/withDefaultShell';
import { LegalFooter } from 'modules/app/fragments/LegalFooter';
import { appActions } from 'modules/app/redux/app-reducer';
import { Colors } from 'modules/app/style/colors';
import { AddCoursesArrowButton } from 'modules/course/components/AddCoursesArrowButton';
import { AssignedCourse } from 'modules/course/components/AssignedCourse';
import {
  COURSE_DISPLAYER_BOTTOM_SPACE,
  COURSE_DISPLAYER_SCROLLER_SPACE,
  COURSE_DISPLAYER_WIDTH,
  CoursesTab,
  CoursesTabText,
  dropShadow
} from 'modules/course/components/CourseDisplayerStyles';
import { withLionTranslate } from 'modules/localization/containers/withLionTranslate';
import { ContentFileIcon } from 'modules/ui/assets/icons/ContentFileIcon';
import chickasawLogo from 'modules/ui/assets/images/chickasaw_seal.png';
import luisenoLogo from 'modules/ui/assets/images/luiseno_logo.png';
import OjibweLogo from 'modules/ui/assets/icons/OjibweLogo';
import { MouseCatcher } from 'modules/ui/components/MouseCatcher';

const TOP_BLOCK_HEIGHT = 174;

const MyCoursesPageDiv = styled.div((props) => {
  // setting fontsize 0 here but it should be set in body
  const style = {
    position: 'relative',
    width: '100%',
    height: '100%',
    fontSize: '0px'
  };
  return style;
});

const MyCoursesTopBlock = styled.div((props) => {
  const style = {
    position: 'absolute',
    top: 0,
    left: 0,
    height: TOP_BLOCK_HEIGHT,
    width: '100%',
    boxShadow: dropShadow,
    zIndex: 2
  };
  return style;
});

const MyCoursesHeader = styled.div((props) => {
  const style = {
    position: 'relative',
    width: COURSE_DISPLAYER_WIDTH,
    marginLeft: 'auto',
    marginRight: 'auto'
  };
  return style;
});

const MyCoursesPageTitle = styled.div((props) => {
  const style = {
    position: 'absolute',
    top: 42,
    left: 1,
    fontSize: '24px',
    fontWeight: 400,
    letterSpacing: 'normal',
    color: Colors.DarkGreyBlue
  };
  if (props.withLogo) {
    style.top = 70;
  }
  if (props.isCic) {
    style.top = 42;
  }
  return style;
});

const IconPositioner = styled.div({
  height: 130,
  width: 130
});

const AddCoursesButtonPositioner = styled.div((props) => {
  const style = {
    position: 'absolute',
    top: 48,
    right: 0
  };
  return style;
});

const LuisenoLogo = styled.div((props) => {
  const logoSize = TOP_BLOCK_HEIGHT - 32;
  const style = {
    position: 'absolute',
    top: (TOP_BLOCK_HEIGHT - logoSize) / 2,
    left: 'calc(100% - ' + logoSize + 'px)',
    width: logoSize,
    height: logoSize,
    backgroundImage: 'url("' + luisenoLogo + '")',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '0px 0px',
    backgroundSize: '100% 100%'
  };
  return style;
});

const ChickasawLogo = styled.div((props) => {
  const logoSize = TOP_BLOCK_HEIGHT - 32;
  const style = {
    position: 'absolute',
    top: (TOP_BLOCK_HEIGHT - logoSize) / 2,
    left: 'calc(100% - ' + logoSize + 'px)',
    width: logoSize,
    height: logoSize,
    backgroundImage: 'url("' + chickasawLogo + '")',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '0px 0px',
    backgroundSize: '100% 100%'
  };
  return style;
});

const OjibweLogoWrapper = styled.div((props) => {
  const logoSize = TOP_BLOCK_HEIGHT - 32;
  const style = {
    position: 'absolute',
    top: (TOP_BLOCK_HEIGHT - logoSize) / 2,
    left: 'calc(100% - ' + logoSize + 'px)',
    width: logoSize,
    height: logoSize
  };
  return style;
});

const CefrTabBar = styled.div((props) => {
  const style = {
    position: 'absolute',
    top: 91,
    left: 1,
    height: 50,
    paddingTop: 12
  };
  return style;
});

const MyCoursesPageBottomBlock = styled.div((props) => {
  const style = {
    position: 'absolute',
    top: TOP_BLOCK_HEIGHT,
    bottom: 0,
    paddingTop: 30,
    paddingBottom: COURSE_DISPLAYER_BOTTOM_SPACE,
    width: '100%'
  };
  return style;
});

const MyCoursesScroller = styled.div((props) => {
  const style = {
    position: 'relative',
    height: '100%',
    width: COURSE_DISPLAYER_WIDTH + 2 * COURSE_DISPLAYER_SCROLLER_SPACE,
    paddingLeft: COURSE_DISPLAYER_SCROLLER_SPACE,
    // giving some blank space at the beginning and end of the scrolling list
    paddingTop: 0,
    paddingBottom: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    overflowY: 'auto'
  };
  return style;
});

const EmptyState = styled.div({
  padding: 80,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column'
});

const EmptyStateText = styled.span(({ large, link }) => ({
  display: 'block',
  fontSize: large ? 24 : 18,
  fontWeight: large ? 500 : 400,
  lineHeight: 1.22,
  color: link ? Colors.PrimaryDark : Colors.LightBlueGrey,
  marginTop: large || link ? 40 : 10,
  textDecoration: link ? 'underline' : 'none',
  cursor: link ? 'pointer' : 'default'
}));

//***************************************************************************************
class MyCoursesPageBase extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      coursesByCefr: null,
      selectedCefr: props.cefr,
      isLuiseno: false,
      isOjibwe: false,
      isChickasaw: false
    };
  }

  componentDidMount() {
    brazeHelper.logBrazeEvent(brazeHelper.EVENT_KEY_LOADED_HOME_SCREEN);
    // NOTE: if there is progress in currentCourseProgress, our state has been mangled
    // so that the sequences don't have a bestGrade, and we need to reload course data.
    if (!this.props.assignedCourses || this.props.progress) {
      this.props.loadAssignedCourses();
    } else {
      this.installAssignedCourses();
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.assignedCourses && this.props.assignedCourses !== prevProps.assignedCourses) {
      this.installAssignedCourses();
      this.sendMyCoursesViewedAmplitudeEvent();
    }
  }

  installAssignedCourses() {
    const isLuiseno = this.checkForCourseLangAndSetUpFonts(this.props.assignedCourses, 'lui');
    const isOjibwe = this.checkForCourseLangAndSetUpFonts(this.props.assignedCourses, 'oji');
    const isChickasaw = this.checkForCourseLangAndSetUpFonts(this.props.assignedCourses, 'cic');

    const coursesByCefr = {};
    this.props.assignedCourses.forEach((assignedCourse) => {
      if (!coursesByCefr[assignedCourse.cefr]) {
        coursesByCefr[assignedCourse.cefr] = [];
      }
      coursesByCefr[assignedCourse.cefr].push(assignedCourse);
    });
    // make sure there is an entry for this user's cefr level even if
    //  there are no assigned courses at this point
    if (this.props.cefr && !coursesByCefr[this.props.cefr]) {
      coursesByCefr[this.props.cefr] = [];
    }

    endInteraction('application-startup');

    this.setState({
      coursesByCefr: coursesByCefr,
      isLuiseno: isLuiseno,
      isOjibwe: isOjibwe,
      isChickasaw: isChickasaw
    });
  }

  sendMyCoursesViewedAmplitudeEvent = () => {
    const coursesTotal = this.props.assignedCourses.length;
    let coursesCompletedCount = 0;
    let coursesInProgressCount = 0;
    let coursesNotStartedCount = 0;
    this.props.assignedCourses.forEach((assignedCourse) => {
      const courseProgress = this.props.assignedCourseProgressInfo(assignedCourse.id);
      if (courseProgress.isCourseCompleted) {
        coursesCompletedCount++;
      } else if (courseProgress.isCourseStarted) {
        coursesInProgressCount++;
      } else {
        coursesNotStartedCount++;
      }
    });
    let coursesCompletedPercent = 0;
    let coursesInProgressPercent = 0;
    let coursesNotStartedPercent = 100;
    if (coursesTotal > 0) {
      coursesCompletedPercent = Math.round((coursesCompletedCount / coursesTotal) * 100);
      coursesInProgressPercent = Math.round((coursesInProgressCount / coursesTotal) * 100);
      coursesNotStartedPercent = Math.round((coursesNotStartedCount / coursesTotal) * 100);
    }
    amplitudeHelper.sendAmplitudeEvent(amplitudeHelper.analyticEventNames.MY_COURSES_VIEWED, {
      coursesTotal: coursesTotal,
      coursesCompletedCount: coursesCompletedCount,
      coursesCompletedPercent: coursesCompletedPercent,
      coursesInProgressCount: coursesInProgressCount,
      coursesInProgressPercent: coursesInProgressPercent,
      coursesNotStartedCount: coursesNotStartedCount,
      coursesNotStartedPercent: coursesNotStartedPercent
    });
  };

  // Filter out the correct Ojibwe courses based on the user's product rights.
  ojibweFilter = (courses) => {
    const productIds = this.props.productRights.map(product => product.productId);
    let filteredCourses = courses.filter(course => productIds.includes(course.productId));
    // Sort filtered courses based on the 'cefr'
    filteredCourses.sort((a, b) => {
      return a.cefr.localeCompare(b.cefr);
    });
    return filteredCourses;
  }

  checkForCourseLangAndSetUpFonts(newAssignedCourses, courseLanguage) {
    if (
      newAssignedCourses.find((assignedCourse) => {
        return assignedCourse.learningLanguage === courseLanguage;
      })
    ) {
      this.props.setAppFontForLanguage(courseLanguage);
      return true;
    }
    return false;
  }

  handleCefrTabClick(cefrLevel) {
    this.setState({ selectedCefr: cefrLevel });
  }

  handleAddCoursesArrowButtonClicked = () => {
    this.props.history.push('/courses');
  };

  launchCourse = (course) => {
    const courseSlug = getSlug(course.title);
    this.props.history.push('/course/' + courseSlug);
  };

  handleRemoveCourse = (course) => {
    this.props.unassignCourse(course.courseId);
    amplitudeHelper.sendAmplitudeEvent(amplitudeHelper.analyticEventNames.COURSE_REMOVED, {
      [amplitudeHelper.analyticEventPropNames.courseId]: course.courseId
    });
  };

  renderEmptyState = () => {
    const { lionTranslate } = this.props;
    return (
      <EmptyState>
        <IconPositioner>
          <ContentFileIcon />
        </IconPositioner>
        <EmptyStateText large>{lionTranslate('_home_no_courses_title')}</EmptyStateText>
        <EmptyStateText>{lionTranslate('_home_no_courses_description')}</EmptyStateText>
        <EmptyStateText link onClick={this.handleAddCoursesArrowButtonClicked}>
          {lionTranslate('_home_add_courses') + ' >'}
        </EmptyStateText>
      </EmptyState>
    );
  };

  renderCefrTab = (cefrLevel) => {
    return (
      <CoursesTab
        key={cefrLevel}
        currentTab={this.state.selectedCefr === cefrLevel}
        onClick={() => {
          this.handleCefrTabClick(cefrLevel);
        }}
      >
        <CoursesTabText>{cefrLevel.toUpperCase()}</CoursesTabText>
      </CoursesTab>
    );
  };

  renderCourse = (course) => {
    const courseProgressInfo = this.props.assignedCourseProgressInfo(course.id);
    return (
      <AssignedCourse
        key={course.id}
        course={course}
        languages={this.props.languages}
        progressInfo={courseProgressInfo}
        removeCourseHandler={this.handleRemoveCourse}
        launchCourseHandler={this.launchCourse}
        canRemoveCourse={!this.state.isLuiseno && !this.state.isOjibwe && !this.state.isChickasaw}
      />
    );
  };

  render() {
    if (!this.props.lionAppResourcesLoaded) {
      return <MouseCatcher />;
    }

    const { assignedCourses, lionTranslate } = this.props;
    const { coursesByCefr, selectedCefr } = this.state;

    if (!assignedCourses || !coursesByCefr) {
      return <MouseCatcher />;
    }

    const cefrTabs = Object.keys(coursesByCefr).sort();
    const coursesToDisplay = this.state.isOjibwe ? this.ojibweFilter(assignedCourses) || [] : coursesByCefr[selectedCefr] || [];
    let addCoursesButton;
    let cefrTabBar;
    if (!this.state.isLuiseno && !this.state.isOjibwe) {
      if (!this.state.isChickasaw){
        addCoursesButton = (
          <AddCoursesButtonPositioner>
            <AddCoursesArrowButton
              clickHandler={this.handleAddCoursesArrowButtonClicked}
              displayText={lionTranslate('_home_add_courses')}
            />
          </AddCoursesButtonPositioner>
        );
      }    
      cefrTabBar = <CefrTabBar>{cefrTabs.map(this.renderCefrTab)}</CefrTabBar>;
    }

    let logo;
    if (this.state.isLuiseno) {
      logo = <LuisenoLogo />;
    } else if (this.state.isOjibwe) {
      logo = (
        <OjibweLogoWrapper>
          <OjibweLogo />
        </OjibweLogoWrapper>
      );
    } else if (this.state.isChickasaw){
      logo = <ChickasawLogo />;
    }

    return (
      <MyCoursesPageDiv data-qa="MyCoursesPageDiv">
        <MyCoursesTopBlock data-qa="MyCoursesTopBlock">
          <MyCoursesHeader>
            <MyCoursesPageTitle data-qa="MyCoursesPageTitle" withLogo={!!logo} isCic={this.state.isChickasaw}>
              {lionTranslate('_availablegoals_courses')}
            </MyCoursesPageTitle>
            {addCoursesButton}
            {cefrTabBar}
            {logo}
          </MyCoursesHeader>
        </MyCoursesTopBlock>
        <MyCoursesPageBottomBlock>
          <MyCoursesScroller>
            {coursesToDisplay.length ? coursesToDisplay.map(this.renderCourse) : this.renderEmptyState()}
          </MyCoursesScroller>
        </MyCoursesPageBottomBlock>
        <LegalFooter />
      </MyCoursesPageDiv>
    );
  }
}

const mapStateToProps = (state) => ({
  progress: progressSelectors.getCurrentCourseProgress(state),
  assignedCourses: courseSelectors.getAssignedCourses(state),
  assignedCourseProgressInfo: (courseId) => {
    return courseSelectors.getAssignedCourseProgressInfo(state, courseId);
  },
  cefr: userSelectors.getCefr(state),
  languages: userSelectors.getLanguages(state),
  productRights: userSelectors.getUserProductRights(state)
});

const mappedActions = {
  loadAssignedCourses: courseActions.loadAssignedCoursesAndProgress,
  unassignCourse: courseActions.unassignCourse,
  setAppFontForLanguage: appActions.setAppFontForLanguage
};

export const MyCoursesPage = compose(
  withLionTranslate,
  connect(
    mapStateToProps,
    mappedActions
  )
)(withDefaultShell(MyCoursesPageBase));
