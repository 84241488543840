import { userSelectors } from '@rosetta/gaia-data';
import { stopAllMedia } from '@rosetta/gaia-media';
import { put, take, race, select, takeLatest } from 'redux-saga/effects';
import { sreActions, sreSelectors } from '@rosetta/react-sre';
import {
  speechActions,
  START_SRE_INITIALIZATION,
  START_LISTEN_FOR_PHRASES,
  SRE_MIC_STORAGE_ID
} from './speech-reducer';

const srePath = '/sre/';
function* initializeSreSaga(action) {
  yield put(sreActions.loadSonicLibrary(srePath));
  yield take(sreActions.SRE_SONIC_LOAD_COMPLETE);

  // we want the SRE soundlog turned on for Ojibwe users
  const learningLanguage = yield select(userSelectors.getLearningLanguage);
  if (learningLanguage === 'oji') {
    const userId = yield select(userSelectors.getUserId);
    yield put(sreActions.enableAudioLog(userId, 'Gaia'));
  }

  yield put(sreActions.startMicCheckFlow());
  yield race({
    micBlocked: take(sreActions.SRE_SPEECH_DISABLE),
    micAllowed: take(sreActions.SRE_CONFIRM_MIC_ACCESS)
  });
  const sreMicAccess = yield select(sreSelectors.sreMicAccess);
  const isMicDeniedInBrowserByUser = sreMicAccess === 'denied';
  if (isMicDeniedInBrowserByUser) {
    return;
  }

  if (action.payload && action.payload.calibrate) {
    // auto-start calibration
    yield startCalibrationSaga();
  } else {
    // bypass calibration
    yield put(sreActions.calibrateComplete('ok'));
  }
}

function* startCalibrationSaga() {
  const isSRECalibrated = yield select(sreSelectors.isSRECalibrated);
  if (!isSRECalibrated) {
    const srePath = '/sre/';
    // we disable cancelling and skipping in Modals.jsx so these are not relevant
    const exitOnCancel = false;
    const exitOnDisableSpeech = false;
    yield put(sreActions.calibrateSRE(exitOnCancel, srePath, srePath + 'sonic_worker.js', exitOnDisableSpeech));
  }
}

function* saveMicPrefsSaga(action) {
  yield localStorage.setItem(SRE_MIC_STORAGE_ID, action.payload.soundInputDevice);
}

function* listenForPhrasesSessionSaga(action) {
  let speakables;
  let prompt;
  if (action.payload) {
    speakables = action.payload.speakables;
    prompt = action.payload.prompt;
  }

  yield stopAllMedia();
  if (Array.isArray(speakables) && speakables.length && prompt.sessionId) {
    // in listenForPhrases the 3rd param is an undefined duration
    //  and the 4th param is whether to use new useLFOP (ListenForOnePhrase)
    yield put(sreActions.listenForPhrases(speakables, prompt.sessionId, undefined, false));
    const result = yield take(sreActions.SRE_LISTEN_FOR_PHRASE_RESULT);

    if (result) {
      yield put(
        speechActions.saveListenForPhrasesResult({
          timestamp: Date.now(),
          score: result.payload.result,
          prompt,
          speakables,
          hypothesis: sreSelectors.sreHypothesis,
          ...result.payload
        })
      );
    }
  }
}

export function* speechSagas() {
  yield takeLatest(START_SRE_INITIALIZATION, initializeSreSaga);
  yield takeLatest(sreActions.SRE_MIC_CHOSEN, saveMicPrefsSaga);
  yield takeLatest(START_LISTEN_FOR_PHRASES, listenForPhrasesSessionSaga);
}
