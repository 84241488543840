import React, { PureComponent } from 'react';
import ReactDOM from 'react-dom';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/core';

const spinnerColor = '#888888';

const spin = keyframes({
  '0%': { transform: 'rotate(0deg)' },
  '100%': { transform: 'rotate(360deg)' }
});

const MouseCatcherDiv = styled.div({
  position: 'fixed',
  top: '0px',
  bottom: '0px',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: '1000000',
  backgroundColor: 'rgba(255, 255, 255, 0.45)'
});

const MousySpinner = styled.div({
  border: '6px solid #f3f3f3',
  borderTop: '6px solid rgba(85, 85, 85, 0.2)',
  borderRadius: '50%',
  width: '40px',
  height: '40px',
  animation: `${spin} 2s linear infinite`,
  borderRightColor: spinnerColor,
  borderBottomColor: spinnerColor,
  borderLeftColor: spinnerColor
});

export class MouseCatcher extends PureComponent {
  static catchersActive = 0;

  componentDidMount() {
    MouseCatcher.catchersActive++;
  }

  componentWillUnmount() {
    MouseCatcher.catchersActive--;
  }

  render() {
    if (MouseCatcher.catchersActive > 1) {
      return null;
    }
    return ReactDOM.createPortal(
      <MouseCatcherDiv data-qa="MouseCatcher">
        <MousySpinner />
      </MouseCatcherDiv>,
      document.body
    );
  }
}
