import gql from 'graphql-tag';

const scoreDetails = gql`
  fragment ScoreDetails on Score {
    score
    maxScore
    cefr
    ilr
    clb
    warning
  }
`;

export const AssessmentQuery = gql`
  query getAssessmentStatus($assessmentId: ID!) {
    assessment(id: $assessmentId) {
      status
      score {
        ...ScoreDetails
      }
      sequence {
        title
        learningLanguage
      }
    }
  }
  ${scoreDetails}
`;

export const AssessmentStepMutation = gql`
  mutation insertAssessmentStep($message: AssessmentStepInput!) {
    assessmentStep(message: $message) {
      assessmentName
      formNumber
      activity {
        activityId
        activityType
        interaction
        skills
        steps {
          activityStepId
          type
          content
          instructions
          correct
          logits
        }
        instructions
        icon
        metadata
      }
      score {
        ...ScoreDetails
      }
      progress {
        questionNo
        noOfQuestions
        section
        tally
        ability
        standardError
      }
    }
  }
  ${scoreDetails}
`;

export const AssessmentStartMutation = gql`
  mutation createAssessment($message: AssessmentStartInput!) {
    assessmentStart(message: $message) {
      id
      startUrl
    }
  }
`;
