import { PureComponent } from 'react';
import { appActions } from '../redux/app-reducer';
import { connect } from 'react-redux';

//***************************************************************************************
class ErrorPageBadPathBase extends PureComponent {
  componentDidMount() {
    this.props.handleError('Page not found: ' + this.props.location.pathname);
  }

  render() {
    return null;
  }
}

const mapStateToProps = (state) => ({});
const mappedActions = {
  handleError: appActions.handleError
};

export const ErrorPageBadPath = connect(
  mapStateToProps,
  mappedActions
)(ErrorPageBadPathBase);
