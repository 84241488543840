// create two images one at 2x resolution
//  import them then do this like
//  ...hiResBgImage(loresImg, hiresImg, '100px', '200px');
export const hiResBgImage = (loresImg, hiresImg, width, height) => {
  const densePixelsQuery = {
    densePixels:
      '@media all and (-webkit-min-device-pixel-ratio: 1.5),' +
      'all and (min--moz-device-pixel-ratio: 1.5),' +
      'all and (min-device-pixel-ratio: 1.5)'
  };
  return {
    backgroundImage: 'url("' + loresImg + '")',
    backgroundSize: width + ' ' + height,
    [densePixelsQuery.densePixels]: {
      backgroundImage: 'url("' + hiresImg + '")'
    }
  };
};
