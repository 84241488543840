import { userActions } from '@rosetta/gaia-data';
import React, { PureComponent } from 'react';
import { compose } from 'react-apollo';
import { connect } from 'react-redux';
import styled from '@emotion/styled';

import { Colors } from 'modules/app/style/colors';
import { appActions, appSelectors } from 'modules/app/redux/app-reducer';
import { withLionTranslate } from 'modules/localization/containers/withLionTranslate';
import { ButtonPrimary } from 'modules/ui/components/ButtonPrimary';
import { withHover } from 'modules/ui/containers/withHover';
import { hiResBgImage } from 'modules/ui/mixins';
import { CloseXIcon } from 'modules/ui/assets/icons/CloseXIcon';

import robot1x from 'modules/ui/assets/images/sad_robot_1x.png';
import robot2x from 'modules/ui/assets/images/sad_robot_2x.png';

const ErrorPageDiv = styled.div({
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: Colors.Secondary
});

const ErrorPageContentDiv = styled.div({
  position: 'relative',
  width: 780,
  height: 350,
  flex: 'none'
});

const RobotContainer = styled.div(() => {
  const WIDTH = 403;
  const HEIGHT = 231;

  return {
    position: 'relative',
    top: 56,
    left: 2,
    width: WIDTH,
    height: HEIGHT,
    ...hiResBgImage(robot1x, robot2x, WIDTH + 'px', HEIGHT + 'px')
  };
});

const ErrorPageRightSide = styled.div({
  position: 'absolute',
  top: 45,
  right: 0,
  width: 360,
  height: 240
});

const ErrorTitleText = styled.div({
  position: 'absolute',
  top: 0,
  left: 3,
  right: 0,
  height: 28,
  color: Colors.DarkGreyBlue,
  fontSize: '21px',
  fontWeight: 'normal',
  textAlign: 'left'
});

const ErrorNumberDisp = styled.div({
  position: 'absolute',
  top: 36,
  height: 24,
  left: 3,
  right: 0,
  color: Colors.DarkGreyBlue,
  fontSize: '15px',
  fontWeight: 'normal',
  textAlign: 'left',
  userSelect: 'text',
  MozUserSelect: 'text'
});

const ErrorFeedbackInput = styled.textarea({
  position: 'absolute',
  top: 80,
  bottom: 0,
  left: 0,
  width: '100%',
  color: Colors.DarkGreyBlue,
  fontSize: '16px',
  fontWeight: 'normal',
  textAlign: 'left',
  padding: 16,
  paddingTop: 12,
  border: '1px solid ' + Colors.LightGrey,
  borderRadius: '4px',
  resize: 'none',
  ':focus': {
    outline: 'none',
    borderColor: Colors.SelectedBlue
  },
  overflowY: 'auto',
  '::placeholder': {
    color: Colors.LightGrey
  }
});

const SubmitErrorButton = styled(ButtonPrimary)({
  position: 'absolute',
  bottom: 0,
  right: 0,
  width: 156
});

const FeedbackSuccessDiv = styled.div({
  position: 'absolute',
  top: 80,
  bottom: 0,
  left: 0,
  width: '100%',
  border: '1px solid ' + Colors.LightGrey,
  borderRadius: '4px',
  backgroundColor: Colors.Light
});

const FeedbackSuccessText = styled.div({
  position: 'absolute',
  top: 24,
  left: 0,
  right: 0,
  color: Colors.MediumGrey,
  fontSize: '18px',
  fontWeight: 'normal',
  textAlign: 'center'
});

const FeedbackSuccessDescription = styled.div({
  position: 'absolute',
  top: 66,
  left: 40,
  right: 40,
  color: Colors.DarkGreyBlue,
  fontSize: '16px',
  fontWeight: 'normal',
  textAlign: 'center'
});

const CloseButtonDiv = styled.div({
  position: 'absolute',
  top: 16,
  right: 0,
  width: 20,
  height: 20,
  cursor: 'pointer'
});

const CloseButton = withHover(({ hovering }) => (
  <CloseButtonDiv>
    <CloseXIcon xColor={hovering ? Colors.MediumGrey : Colors.DarkGrey} />
  </CloseButtonDiv>
));

//***************************************************************************************
class ErrorPageBase extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      doingInput: true,
      feedback: ''
    };
  }

  handleInputChanged = (event) => {
    this.setState({ feedback: event.target.value });
  };

  handleSubmitButtonClicked = () => {
    const annotatedFeedback = 'errbit ID: ' + this.props.errorId + '\n\n' + this.state.feedback;
    this.props.sendFeedback(annotatedFeedback);
    this.setState({
      doingInput: false
    });
  };

  handleCloseButtonClicked = () => {
    this.props.clearError();
    window.location.assign('/');
  };

  renderFeedbackWidgets = () => {
    const errorInputPromptStr = this.props.lionTranslate('_error_please_describe');
    const feedbackSentTitle = this.props.lionTranslate('_beta_feedback_sent_title');
    const feedbackDescription = this.props.lionTranslate('_beta_feedback_sent_description');
    const submitStr = this.props.lionTranslate('_error_submit');

    let inputField = null;
    let submitButton = null;
    let feedbackSentDisp = null;
    if (this.state.doingInput) {
      inputField = (
        <ErrorFeedbackInput
          onChange={this.handleInputChanged}
          placeholder={errorInputPromptStr}
          value={this.state.feedback}
        />
      );
      submitButton = (
        <SubmitErrorButton disabled={!this.state.feedback.length} onClick={this.handleSubmitButtonClicked}>
          {submitStr}
        </SubmitErrorButton>
      );
    } else {
      feedbackSentDisp = (
        <FeedbackSuccessDiv>
          <FeedbackSuccessText>{feedbackSentTitle}</FeedbackSuccessText>
          <FeedbackSuccessDescription>{feedbackDescription}</FeedbackSuccessDescription>
        </FeedbackSuccessDiv>
      );
    }
    return { inputField, submitButton, feedbackSentDisp };
  };

  render() {
    const { lionTranslate, errorId } = this.props;
    const errorTitleStr = lionTranslate('_error_something_went_wrong');
    const errNumStr = lionTranslate('_error_number', { error_number: errorId || ' ' });

    // reinstate this if we want to have user feedback again
    // const feedbackWidgets = this.renderFeedbackWidgets();

    const feedbackWidgets = {};

    return (
      <ErrorPageDiv data-qa="ErrorPage">
        <ErrorPageContentDiv>
          <RobotContainer />
          <CloseButton clickHandler={this.handleCloseButtonClicked} />
          <ErrorPageRightSide>
            <ErrorTitleText>{errorTitleStr}</ErrorTitleText>
            <ErrorNumberDisp>{errNumStr}</ErrorNumberDisp>
            {feedbackWidgets.inputField}
            {feedbackWidgets.feedbackSentDisp}
          </ErrorPageRightSide>
          {feedbackWidgets.submitButton}
        </ErrorPageContentDiv>
      </ErrorPageDiv>
    );
  }
}

const mapStateToProps = (state) => ({
  errorId: appSelectors.errorId(state)
});
const mappedActions = {
  sendFeedback: userActions.sendFeedback,
  clearError: appActions.setErrorId
};

export const ErrorPage = compose(
  connect(
    mapStateToProps,
    mappedActions
  ),
  withLionTranslate
)(ErrorPageBase);
