export const getS3Config = () => {
  const host = window.location.host;
  if (/learn-/.test(host)) {
    const env = host.match(/learn-(\w+)/)[1];
    return {
      serverUrl: `https://gaia-server-${env}.dev.rosettastone.com`,
      loginUrl: `https://login-${env}.dev.rosettastone.com/#/login`,
      logoutUrl: `https://login-${env}.dev.rosettastone.com/#/logout`,
      redirectParam: '?client_id=Product:FB&response_type=code&redirect_uri=',
      profileUrl: `https://login-${env}.dev.rosettastone.com/#/preferences`,
      domain: '.dev.rosettastone.com',
      errbitUrl: 'https://errbit.stg.rosettastone.com/',
      errbitApi: 'ba4b94749910f42eda8dc695e7f5b5c3',
      brazeApiKey: 'a6500576-763f-4b08-804f-9c1a1d7f9031',
      amplitudeKey: 'noMoreAmplitude',
      env: env,
      appName: 'Fluency Builder'
    };
  } else {
    //PROD
    return {
      prod: true,
      serverUrl: `https://gaia-server.rosettastone.com`,
      loginUrl: `https://login.rosettastone.com/#/login`,
      logoutUrl: `https://login.rosettastone.com/#/logout`,
      redirectParam: '?client_id=Product:FB&response_type=code&redirect_uri=',
      profileUrl: 'https://login.rosettastone.com/#/preferences',
      trackingId: 'UA-102502396-3',
      domain: '.rosettastone.com',
      //env: 'production',  AEB-13380 and Datadog fixes - WD 2022
      env: 'prod',
      errbitUrl: 'https://500s.rosettastone.com/',
      errbitApi: '512fa1aa5c600c56c50c9faf38b44a1c',
      brazeApiKey: 'c60008bd-a2fe-439c-a56f-e1dc03c2d78a',
      amplitudeKey: 'noMoreAmplitude',
      appName: 'Fluency Builder'
    };
  }
};
