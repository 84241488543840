import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Colors } from 'modules/app/style/colors';
import { ArrowForwardIcon } from 'modules/ui/assets/icons/ArrowForwardIcon';
import styled from '@emotion/styled';
import { withHover } from 'modules/ui/containers/withHover';

// the arrow button for "go to available courses"
const AddCoursesArrowButtonDiv = styled.div((props) => {
  const style = {
    display: 'inline-block',
    height: 32,
    paddingLeft: 20,
    paddingRight: 8,
    cursor: 'pointer'
  };
  return style;
});

const AddCoursesArrowButtonIconContainer = styled.div((props) => {
  const style = {
    display: 'inline-block',
    verticalAlign: 'top',
    marginTop: 6,
    marginLeft: 18,
    height: 17,
    width: 17
  };
  return style;
});

const AddCoursesArrowButtonText = styled.div((props) => {
  const style = {
    display: 'inline-block',
    verticalAlign: 'top',
    fontSize: '18px',
    letterSpacing: '.2px',
    fontWeight: 400,
    color: Colors.DarkGreyBlue,
    height: 32,
    paddingTop: 1.5
  };
  return style;
});

class AddCoursesArrowButtonBase extends PureComponent {
  static propTypes = {
    clickHandler: PropTypes.func.isRequired,
    displayText: PropTypes.string
  };

  render() {
    let iconFillColor = Colors.DarkGreyBlue;
    if (this.props.hovering) {
      if (this.props.active) {
        iconFillColor = Colors.SelectedBlue;
      } else {
        iconFillColor = Colors.SelectedBlue;
      }
    }
    return (
      <AddCoursesArrowButtonDiv>
        <AddCoursesArrowButtonText data-qa="AddCoursesText">{this.props.displayText}</AddCoursesArrowButtonText>
        <AddCoursesArrowButtonIconContainer data-qa="AddCoursesArrowButtonIconContainer">
          <ArrowForwardIcon fillColor={iconFillColor} />
        </AddCoursesArrowButtonIconContainer>
      </AddCoursesArrowButtonDiv>
    );
  }
}

export const AddCoursesArrowButton = withHover(AddCoursesArrowButtonBase);
