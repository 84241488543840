import styled from '@emotion/styled';
import { Colors } from 'modules/app/style/colors';

export const COURSE_DISPLAYER_IMAGE_AREA_WIDTH = 328;
export const COURSE_DISPLAYER_HEIGHT = 185;
export const COURSE_DISPLAYER_WIDTH = 1032;
export const COURSE_DISPLAYER_SCROLLER_SPACE = 46;
export const COURSE_DISPLAYER_BOTTOM_SPACE = 36;

export const dropShadow = '0px -1px 10px 0px' + Colors.UnselectedGrey;

export const CourseDisplayerImageDiv = styled.div((props) => {
  const style = {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: COURSE_DISPLAYER_IMAGE_AREA_WIDTH,
    backgroundColor: Colors.BackgroundDarkBlue,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  };
  return style;
});

export const CourseDisplayerInfoDiv = styled.div((props) => {
  // width and position offsets by 1 to hide left border under image area
  const style = {
    position: 'relative',
    display: 'inline-block',
    backgroundColor: Colors.Light,
    border: '1px solid ' + Colors.LightGrey,
    height: '100%',
    width: COURSE_DISPLAYER_WIDTH - (COURSE_DISPLAYER_IMAGE_AREA_WIDTH - 1),
    left: COURSE_DISPLAYER_IMAGE_AREA_WIDTH - 1,
    borderBottomRightRadius: 6,
    borderTopRightRadius: 6
  };
  return style;
});

export const CourseDisplayerTopicText = styled.div((props) => {
  const style = {
    position: 'absolute',
    top: 18,
    left: 24,
    fontSize: '14px',
    fontWeight: 400,
    letterSpacing: '0.8px',
    color: props.topicColor
  };
  return style;
});

export const CourseDisplayerTitleText = styled.div({
  position: 'absolute',
  top: 42,
  left: 24,
  fontSize: '21px',
  fontWeight: 400,
  color: Colors.DarkGreyBlue
});

export const CoursesTab = styled.div((props) => {
  const style = {
    display: 'inline-block',
    height: 38,
    paddingLeft: 16,
    paddingRight: 16,
    marginRight: 16,
    color: Colors.DarkGreyBlue,
    whiteSpace: 'nowrap',
    cursor: 'pointer'
  };
  if (props.currentTab) {
    style.borderBottom = '3px solid ' + Colors.SelectedBlue;
    style.cursor = 'default';
  }
  return style;
});

export const CoursesTabText = styled.div((props) => {
  const style = {
    display: 'inline-block',
    verticalAlign: 'top',
    fontWeight: 500,
    fontSize: '18px'
  };
  return style;
});
