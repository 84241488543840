import React, { lazy, Suspense } from 'react';
import { MouseCatcher } from 'modules/ui/components/MouseCatcher';

const LazyAdminPage = lazy(() => import('modules/admin/pages/AdminPage'));
export const AdminPage = (props) => (
  <Suspense fallback={<MouseCatcher />}>
    <LazyAdminPage {...props} />
  </Suspense>
);
const LazyDevLoginPage = lazy(() => import('modules/dev/LoginPage'));
export const DevLoginPage = (props) => (
  <Suspense fallback={<MouseCatcher />}>
    <LazyDevLoginPage {...props} />
  </Suspense>
);

const LazyAssessmentPage = lazy(() => import('modules/assessment/pages/AssessmentPage'));
export const AssessmentPage = (props) => (
  <Suspense fallback={<MouseCatcher />}>
    <LazyAssessmentPage {...props} />
  </Suspense>
);

const LazyAddCoursesPage = lazy(() => import('modules/course/pages/AddCoursesPage'));
export const AddCoursesPage = (props) => (
  <Suspense fallback={<MouseCatcher />}>
    <LazyAddCoursesPage {...props} />
  </Suspense>
);

const LazyCoursePage = lazy(() => import('modules/course/pages/CoursePage'));
export const CoursePage = (props) => (
  <Suspense fallback={<MouseCatcher />}>
    <LazyCoursePage {...props} />
  </Suspense>
);

const LazySequencePage = lazy(() => import('modules/sequence/pages/SequencePage'));
export const SequencePage = (props) => (
  <Suspense fallback={<MouseCatcher />}>
    <LazySequencePage {...props} />
  </Suspense>
);

const LazyCardStepPage = lazy(() => import('modules/step-card/pages/CardStepPage'));
export const CardStepPage = (props) => (
  <Suspense fallback={<MouseCatcher />}>
    <LazyCardStepPage {...props} />
  </Suspense>
);

const LazyClozeStepPage = lazy(() => import('modules/step-cloze/pages/ClozeStepPage'));
export const ClozeStepPage = (props) => (
  <Suspense fallback={<MouseCatcher />}>
    <LazyClozeStepPage {...props} />
  </Suspense>
);
const LazyDocumentPage = lazy(() => import('modules/step-card/pages/DocumentPage'));
export const DocumentPage = (props) => (
  <Suspense fallback={<MouseCatcher />}>
    <LazyDocumentPage {...props} />
  </Suspense>
);
const LazyExpertGradingStepPage = lazy(() => import('modules/step-expert/pages/ExpertGradingStepPage'));
export const ExpertGradingStepPage = (props) => (
  <Suspense fallback={<MouseCatcher />}>
    <LazyExpertGradingStepPage {...props} />
  </Suspense>
);
const LazyMultipleChoiceStepPage = lazy(() => import('modules/step-multiple-choice/pages/MultipleChoiceStepPage'));
export const MultipleChoiceStepPage = (props) => (
  <Suspense fallback={<MouseCatcher />}>
    <LazyMultipleChoiceStepPage {...props} />
  </Suspense>
);
const LazyPronunciationStepPage = lazy(() => import('modules/step-pronunciation/pages/PronunciationStepPage'));
export const PronunciationStepPage = (props) => (
  <Suspense fallback={<MouseCatcher />}>
    <LazyPronunciationStepPage {...props} />
  </Suspense>
);
const LazySequencingPage = lazy(() => import('modules/step-sequencing/pages/SequencingPage'));
export const SequencingPage = (props) => (
  <Suspense fallback={<MouseCatcher />}>
    <LazySequencingPage {...props} />
  </Suspense>
);
const LazyWritingStepPage = lazy(() => import('modules/step-writing/pages/WritingStepPage'));
export const WritingStepPage = (props) => (
  <Suspense fallback={<MouseCatcher />}>
    <LazyWritingStepPage {...props} />
  </Suspense>
);
const LazyMatchingStepPage = lazy(() => import('modules/step-matching/pages/MatchingStepPage'));
export const MatchingStepPage = (props) => (
  <Suspense fallback={<MouseCatcher />}>
    <LazyMatchingStepPage {...props} />
  </Suspense>
);
const LazyUnknownActivityPage = lazy(() => import('modules/step/pages/UnknownActivityPage'));
export const UnknownActivityPage = (props) => (
  <Suspense fallback={<MouseCatcher />}>
    <LazyUnknownActivityPage {...props} />
  </Suspense>
);
const LazyGetTheAppPage = lazy(() => import('modules/app/pages/GetTheAppPage'));
export const GetTheAppPage = (props) => (
  <Suspense fallback={<MouseCatcher />}>
    <LazyGetTheAppPage {...props} />
  </Suspense>
);
