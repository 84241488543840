import styled from '@emotion/styled';
import { Colors } from 'modules/app/style/colors';
import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import React from 'react';

//*********************************************************************************
export class LearningProgressBar extends PureComponent {
  static propTypes = {
    bgColor: PropTypes.string.isRequired,
    displayNoTestProgress: PropTypes.bool,
    isCourseBar: PropTypes.bool,
    isRounded: PropTypes.bool.isRequired,
    isTestSequence: PropTypes.bool,
    percentCorrect: PropTypes.number,
    percentIncorrect: PropTypes.number,
    percentComplete: PropTypes.number.isRequired,
    showCompletedSuccessfully: PropTypes.bool.isRequired,
    showFailing: PropTypes.bool.isRequired
  };

  constructor(props) {
    super(props);
    this.barBgRef = React.createRef();
    this.state = {
      barBgWidth: -1,
      barBgHeight: -1,
      indicatorContainerWidth: 0
    };
  }

  componentDidMount() {
    this.updateWidth();
  }

  componentDidUpdate() {
    this.updateWidth();
    const correctWidth = this.props.percentCorrect * this.state.barBgWidth;
    const incorrectWidth = this.props.percentIncorrect * this.state.barBgWidth;
    this.setState({
      indicatorContainerWidth: correctWidth + incorrectWidth
    });
  }

  updateWidth = () => {
    if (this.barBgRef.current) {
      const bgNode = this.barBgRef.current;
      const bgRect = bgNode.getBoundingClientRect();
      this.setState({
        barBgWidth: bgRect.width,
        barBgHeight: bgRect.height
      });
    }
  };

  BarBackground = styled.div(() => {
    let backgroundColor = this.props.bgColor;
    if (this.state.barBgHeight === -1) {
      // don't draw anything the first time until we know about height
      //  so we can set rounded corners
      backgroundColor = 'none';
    }
    const style = {
      width: '100%',
      height: '100%',
      backgroundColor: backgroundColor,
      borderRadius: this.props.isRounded ? this.state.barBgHeight * 0.66666 + 'px' : '0px'
    };
    return style;
  });

  CourseBarIndicator = styled.div(() => {
    const style = {
      height: this.state.barBgHeight,
      width: this.props.percentComplete * this.state.barBgWidth,
      backgroundColor: Colors.SelectedBlue,
      borderRadius: this.props.isRounded ? this.state.barBgHeight * 0.66666 + 'px' : '0px',
      transition: 'width 300ms'
    };
    if (this.props.showCompletedSuccessfully) {
      style.backgroundColor = Colors.BrightGreen;
    }
    return style;
  });

  BarIndicatorContainer = styled.div(() => {
    return {
      width: this.state.indicatorContainerWidth,
      transition: 'width 300ms',
      display: 'flex'
    };
  });

  BarIndicatorCorrect = styled.div(() => {
    const style = {
      width: this.props.percentCorrect * this.state.barBgWidth,
      height: this.state.barBgHeight,
      backgroundColor: Colors.BrightGreen,
      borderRadius: this.props.isRounded ? this.state.barBgHeight * 0.66666 + 'px' : '0px'
    };

    // remove the rounded corners where green touches red
    // this won't happen for test sequences because they are pass/fail
    if (!this.props.isTestSequence && this.props.percentIncorrect > 0) {
      style.borderTopRightRadius = 0;
      style.borderBottomRightRadius = 0;
    }
    if (this.props.isTestSequence && this.props.showCompletedSuccessfully) {
      style.width = this.state.barBgWidth;
    }
    return style;
  });

  BarIndicatorIncorrect = styled.div(() => {
    const style = {
      width: this.props.percentIncorrect * this.state.barBgWidth,
      height: this.state.barBgHeight,
      backgroundColor: Colors.FailingIconColor,
      borderRadius: this.props.isRounded ? this.state.barBgHeight * 0.66666 + 'px' : '0px'
    };

    // remove the rounded corners where red touches green
    // this won't happen for test sequences because they are pass/fail
    if (!this.props.isTestSequence && this.props.percentCorrect > 0) {
      style.borderTopLeftRadius = 0;
      style.borderBottomLeftRadius = 0;
    }
    if (this.props.isTestSequence && this.props.showFailing) {
      style.width = this.state.barBgWidth;
    }
    return style;
  });

  render() {
    let barIndicator = null;
    const isPassingTest = this.props.isTestSequence && this.props.showCompletedSuccessfully;
    const isFailingTest = this.props.isTestSequence && this.props.showFailing;
    const isPracticeSequenceWithCorrectActivities = !this.props.isTestSequence && this.props.percentCorrect;
    const isPracticeSequenceWithIncorrectActivities = !this.props.isTestSequence && this.props.percentIncorrect;
    // only show the indicator once we know how big the bg is, and if there's something to indicate
    // progress for test sequences should not display if a learner exits before completion
    if (this.state.barBgWidth !== -1 && this.props.percentComplete > 0 && !this.props.displayNoTestProgress) {
      if (this.props.isCourseBar) {
        barIndicator = this.props.percentComplete ? <this.CourseBarIndicator /> : null;
      } else {
        barIndicator = (
          <this.BarIndicatorContainer>
            {isPassingTest || isPracticeSequenceWithCorrectActivities ? <this.BarIndicatorCorrect /> : null}
            {isFailingTest || isPracticeSequenceWithIncorrectActivities ? <this.BarIndicatorIncorrect /> : null}
          </this.BarIndicatorContainer>
        );
      }
    }
    return <this.BarBackground ref={this.barBgRef}>{barIndicator}</this.BarBackground>;
  }
}
