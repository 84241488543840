import React from 'react';
import styled from '@emotion/styled';
import { TOP_NAVBAR_HEIGHT } from 'modules/app/fragments/TopNavbar';
import { Colors } from 'modules/app/style/colors';
import { TopNavbarFragment } from 'modules/app/fragments/TopNavbar';

const DefaultShellContentDiv = styled.div({
  position: 'absolute',
  top: TOP_NAVBAR_HEIGHT,
  right: 0,
  bottom: 0,
  left: 0,
  overflowY: 'auto',
  backgroundColor: Colors.AppBackground
});

export const withDefaultShell = (WrappedComponent) => {
  return (props) => (
    <React.Fragment>
      <TopNavbarFragment />
      <DefaultShellContentDiv>
        <WrappedComponent {...props} />
      </DefaultShellContentDiv>
    </React.Fragment>
  );
};
