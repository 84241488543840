import { Notifier } from '@airbrake/browser';
import { isDevelopment, isLocalhost, isUnderCI, getConfig } from '../configs/config-utils';
import { GaiaApolloClient } from '@rosetta/gaia-data';
import { v4 as uuidV4 } from 'uuid';

const { errbitUrl, errbitApi, env } = getConfig();

function buildErrBitClient() {
  if (!errbitUrl || !errbitApi) {
    return undefined;
  }

  const result = new Notifier({
    host: errbitUrl,
    projectKey: errbitApi,
    environment: env,
    projectId: 1
  });

  return result;
}

const errbitClient = buildErrBitClient();

class gaiaWebLogger {
  reduxStore = null;

  /**
   * debug will print only on localhost or CI
   */
  debug = (...args) => {
    if (isLocalhost() || isUnderCI()) console.log(...args);
  };

  /**
   * log info and warn will print only on localhost and QAx. Will not in CI
   */
  log = (...args) => {
    if (isDevelopment() && !isUnderCI()) console.log(...args);
  };
  info = (...args) => {
    if (isDevelopment() && !isUnderCI()) console.info(...args);
  };
  warn = (...args) => {
    if (isDevelopment() && !isUnderCI()) console.warn(...args);
  };

  // error will print anywhere including prod
  error = (...args) => {
    console.error(...args);
  };

  // Restore values as needed based on debug context. These seemed superfluous.
  superfluousApolloFields = [
    'ImageArray:',
    'LocalizedTitle:',
    'Course:',
    'Topic:',
    'Sequence:',
    'Image:',
    'decryptionKey:',
    'Objective:',
    'ProgressActivity:',
    'ProgressAnswer:',
    'ProgressActivityAttempt:',
    'ProgressActivityStep:',
    'ProgressActivityStepAttempt:',
    'Submission:'
  ];

  scrubApolloData = (apolloData) => {
    const result = {};
    for (const [fieldName] of Object.entries(apolloData)) {
      if (!this.superfluousApolloFields.find((prefix) => fieldName.startsWith(prefix))) {
        result[fieldName] = apolloData[fieldName];
      }
    }
    return result;
  };

  // Discard rarely used/less useful data. Restore values as needed based on debug context.
  superfluousReduxFields = [
    'keyboard',
    'lion',
    'media',
    'speech',
    'sre',
    'submissions',
    'tutoring',
    'sequenceId',
    'sequenceVersion',
    'course'
  ];

  scrubReduxStore = (store) => {
    const result = {};
    let fieldName;
    for (fieldName in store) {
      if (!this.superfluousReduxFields.includes(fieldName)) {
        if (fieldName === 'user') {
          result['egoUser'] = { ...store[fieldName].user.egoUser };
          result['egoOrganization'] = { ...store[fieldName].user.egoOrganization };
          result['access_token'] = store[fieldName].user.access_token;
          result['permissions'] = store[fieldName].user.permissions;
          result['roles'] = store[fieldName].user.roles;
        } else {
          result[fieldName] = { ...store[fieldName] };
        }
      }
    }
    return result;
  };

  /**
   * sendToAirbrake will print an error to the console and
   *  send an error to airbrake if configured for this environment
   *  errorObj should be like
   *  { message: "the error message", params: {anything you want to send to airbrake} }
   */
  sendToAirbrake = async (errorObj) => {
    if (!errbitUrl || !errbitApi) {
      console.error(`Errbit server not configured: ${errorObj.message}`);
      return 'mock-error-id-' + Math.floor(Math.random() * 666);
    }

    const payload = {
      id: uuidV4(),
      error: errorObj.message,
      params: {
        ...errorObj.params,
        apolloData: this.scrubApolloData(GaiaApolloClient.extract()),
        reduxState: this.scrubReduxStore(this.reduxStore.getState())
      },
      environment: { env },
      context: { severity: 'error' }
    };

    const responseBody = await errbitClient.notify(payload);
    return responseBody.id;
  };
}

export const logger = new gaiaWebLogger();
