import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { appSelectors } from 'modules/app/redux/app-reducer';
import { getConfig } from 'modules/app/configs/config-utils';
import { MouseCatcher } from 'modules/ui/components/MouseCatcher';
import { userActions, userSelectors, clearTokens } from '@rosetta/gaia-data';

const redirectToLogin = (currentPath, history) => {
  clearTokens();
  const uri = `${getConfig().loginUrl}`;
  let redirect = uri;

  // special complication for convenience of devs - for local
  //  development, goes to a non-Launchpad login page
  if (!!process.env.REACT_APP_USE_DEV_LOGIN) {
    if (currentPath !== '/logout' && currentPath !== '/') {
      redirect = `${uri}${getConfig().redirectParam}${currentPath}`;
    }
    history.push(redirect);
  } else {
    // this is the normal non-dev case which goes to launchpad:
    if (currentPath !== '/logout' && currentPath !== '/') {
      redirect = `${uri}${getConfig().redirectParam}${encodeURIComponent(window.location)}`;
    }
    window.location = redirect;
  }
};

//***************************************************************************************
class NotAuthenticatedBase extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      triedRefreshingToken: false
    };
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.isLoggedIn) {
      // this is to re-engage the routing so we leave the not-authenticated world
      nextProps.history.push(nextProps.location.pathname);
      return null;
    }
    if (nextProps.isAppInitialized && !nextProps.isLoggingIn) {
      if (nextProps.hasRefreshToken) {
        if (prevState.triedRefreshingToken) {
          if (nextProps.refreshTokenFailed) {
            redirectToLogin(nextProps.location.pathname, nextProps.history);
          }
        } else {
          nextProps.refreshToken();
          return { triedRefreshingToken: true };
        }
      } else {
        // they are not logged in and they don't have a stored token either
        //  so just go to login
        redirectToLogin(nextProps.location.pathname, nextProps.history);
      }
    }
    return null;
  }

  render() {
    // we should only be rendering here while we are waiting for refreshToken attempt
    //  so always show a wait screen
    return <MouseCatcher />;
  }
}

const mapStateToProps = (state) => {
  return {
    isLoggedIn: userSelectors.isLoggedIn(state),
    isLoggingIn: userSelectors.isLoggingIn(state),
    hasRefreshToken: !!localStorage.getItem('refreshToken'),
    refreshTokenFailed: userSelectors.refreshTokenFailed(state),
    isAppInitialized: appSelectors.isAppInitialized(state)
  };
};

const mappedActions = {
  refreshToken: userActions.refreshToken
};

export const NotAuthenticatedPage = connect(
  mapStateToProps,
  mappedActions
)(NotAuthenticatedBase);
