export const Colors = {
  Text: '#4c5663',
  Dark: '#2f353d',
  Neutral: '#dfe4e8',
  PrimaryLight: '#34a3e7',
  PrimaryDark: '#4195d3',
  PrimaryDarker: '#4175be',
  Secondary: '#f0f2f5',
  Correct: '#a8d173',
  Info: '#a68ce4',
  Incorrect: '#ec673f',
  Light: '#ffffff',
  Black: '#000000',
  FeedbackNotStarted: '#e9ebee',
  FeedbackBad: '#f94a39',
  FeedbackPoor: '#f99c38',
  FeedbackAverage: '#f9dc4b',
  FeedbackGood: '#8fce3e',
  FeedbackExcellent: '#46a918',

  VeryDarkGreyBlue: '#0a1d37',
  DarkGreyBlue: '#182d36',
  MediumDarkGreyBlue: '#7a8590',
  DarkBlue: '#2c638c',
  SelectedBlue: '#34a4e7',
  HoverBlue: '#50c0f4',
  ActiveBlue: '#3090df',
  VeryLightBlue: '#eef5fa',
  VeryLightBlueGrey: '#ebedf0',
  BorderGrey: '#d7dce0',
  VeryVeryLightGrey: '#fafafa',
  VeryLightGrey: '#f2f2f2',
  LightGrey: '#e6e6e6',
  LightGreyAlt: '#e2e2e2',
  MediumLightGrey: '#cccccc',
  UnselectedGrey: '#b3b3b3',
  MediumGrey: '#979797',
  MediumDarkGrey: '#7a7a7a',
  DarkGrey: '#4a4a4a',
  VeryDarkGrey: '#242424',
  BrightGreen: '#99cc66',
  BackgroundDarkBlue: '#192d48',
  AppBackground: '#f1f3f6',
  UserIconColor: '#071e38',
  FailingIconColor: '#fa624d',
  NavbarItemHover: '#0056b3',
  GoldStarColor: '#ffbb00',

  MenuHiliteColor: '#c9eafe',
  LightBlueGrey: '#a5bacc',

  DarkShadeOne: '#3e3d3f',
  DarkShadeTwo: '#84898e',
  PrimaryHoverBlue: '#a0cae9',
  PrimaryHoverActiveGrey: '#0b1923',
  SecondaryHoverGrey: '#f1f1f1',

  SentenceHoverBackground: '#ecf4fb',
  DroppedChoiceInError: '#fdc7c0',
  DroppedChoiceCorrected: '#fbe173',

  ClickedChoiceCorrected: '#fbcf10',

  ModalBackgroundColor: 'rgba(0, 0, 0, 0.25)',
  BoxShadowColor: 'rgba(0, 0, 0, 0.1)',

  KeyboardBackgroundColor: '#f1f1f1',
  DeadKeyColor: '#fef1c4',
  DeadKeyHoverColor: '#fae17d',
  DeadKeyActiveColor: '#fae696',
  DeadKeySelectedColor: '#fbcf10',

  NeutralShade400: '#797979',
  NeutralShade500: '#3f3f3f'
};
