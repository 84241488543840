import styled from '@emotion/styled';
import React, { PureComponent } from 'react';
import { Colors } from 'modules/app/style/colors';
import { withLionTranslate } from 'modules/localization/containers/withLionTranslate';
import { ContentFileIcon } from 'modules/ui/assets/icons/ContentFileIcon';
import { ButtonPrimary } from 'modules/ui/components/ButtonPrimary';
import { modalZIndex } from '../constants';

const BackgroundDiv = styled.div({
  position: 'fixed',
  top: 0,
  bottom: 0,
  right: 0,
  left: 0,
  backgroundColor: Colors.ModalBackgroundColor,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: modalZIndex
});

const ModalDiv = styled.div({
  position: 'relative',
  height: 350,
  width: 550,
  backgroundColor: Colors.Light,
  borderRadius: '6px',
  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.3)',
  marginBottom: 60
});

const OKButton = styled(ButtonPrimary)({
  position: 'absolute',
  height: 40,
  width: 160,
  bottom: 48,
  left: 195
});

const IconPositioner = styled.div({
  position: 'absolute',
  top: 60,
  left: 225,
  height: 100,
  width: 100
});

const ModalText = styled.div({
  position: 'absolute',
  top: 190,
  height: 60,
  left: 84,
  right: 84,
  color: Colors.DarkGreyBlue,
  fontSize: 16,
  lineHeight: '18px',
  fontWeight: 'normal',
  textAlign: 'center'
});

//***************************************************************************************
class MultipleInstanceModalBase extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      clickedOK: false
    };
  }

  handleOKButtonClicked = (e) => {
    this.setState({ clickedOK: true });
  };

  render() {
    if (this.state.clickedOK) {
      return null;
    }

    return (
      <BackgroundDiv>
        <ModalDiv>
          <IconPositioner>
            <ContentFileIcon />
          </IconPositioner>
          <ModalText>{this.props.lionTranslate('_app_open_in_another_tab')}</ModalText>
          <OKButton onClick={this.handleOKButtonClicked}>{this.props.lionTranslate('_ok')}</OKButton>
        </ModalDiv>
      </BackgroundDiv>
    );
  }
}

export const MultipleInstanceModal = withLionTranslate(MultipleInstanceModalBase);
