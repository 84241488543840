import isEmpty from 'lodash/isEmpty';

const setGtmData = (newDataObj = {}) => {
  if (!newDataObj || isEmpty(newDataObj)) return;

  // ensure data layer exists and is an emtpy array
  window.dataLayer = window.dataLayer || [];

  window.dataLayer.push(newDataObj);
};

export function* initGoogleTagManagerCallback() {
  const tagsData = {
    event: 'loginStatusesInitialized', // trigger to sync data layer vars to GTM
    consumerUser: false,
    viperUser: false,
    HS: true, // has signed in
    SS: true, // student subscribed
    ES: true // enterprise subscribed
  };

  // allow a second for GTM to load script tag and init,
  // so window.dataLayer exists and is an array
  setGtmData(tagsData);

  yield true;
}
