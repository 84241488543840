import { PureComponent } from 'react';
import React from 'react';
import styled from '@emotion/styled';
import { Colors } from 'modules/app/style/colors';
import { withLionTranslate } from 'modules/localization/containers/withLionTranslate';

const FooterContainer = styled.div((props) => {
  const style = {
    position: 'absolute',
    bottom: 9,
    width: '100%',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
    fontWeight: 400,
    letterSpacing: '0.18px',
    fontSize: 12
  };
  return style;
});

const FooterTextDisp = styled.div((props) => {
  const style = {
    display: 'inline-block',
    flexGrow: 'none',
    flexShrink: 'none',
    whiteSpace: 'nowrap',
    color: Colors.DarkGreyBlue,
    paddingRight: 6,
    paddingLeft: 6
  };
  return style;
});

const CopyrightDisp = styled(FooterTextDisp)((props) => {
  return {
    paddingLeft: 0,
    paddingRight: 20
  };
});

const FooterLink = styled.a((props) => {
  const style = {
    display: 'inline-block',
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    color: Colors.DarkGreyBlue,
    textDecoration: 'none',
    ':hover': {
      opacity: 0.5,
      textDecoration: 'none'
    },
    ':hover:active': {
      opacity: 0.8,
      textDecoration: 'none'
    }
  };
  return style;
});

//***************************************************************************************
class LegalFooterBase extends PureComponent {
  copyrightStr = null;
  getCopyright = () => {
    if (!this.copyrightStr) {
      const today = new Date();
      const year = today.getFullYear().toString();
      this.copyrightStr = this.props.lionTranslate('_footer_copyright', { year: year });
    }
    return this.copyrightStr;
  };

  render() {
    return (
      <FooterContainer>
        <CopyrightDisp>{this.getCopyright()}</CopyrightDisp>
        <FooterLink href="http://www.rosettastone.com/interactiveproductprivacy" target="_blank" tabIndex={-1}>
          {this.props.lionTranslate('_privacy_policy')}
        </FooterLink>
        <FooterTextDisp>|</FooterTextDisp>
        <FooterLink href="http://www.rosettastone.com/termsonline" target="_blank" tabIndex={-1}>
          {this.props.lionTranslate('_terms_of_service')}
        </FooterLink>
      </FooterContainer>
    );
  }
}

export const LegalFooter = withLionTranslate(LegalFooterBase);
