import React from 'react';

export const PersonInCircleIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      preserveAspectRatio="none"
      viewBox="0 0 1060 1060"
    >
      <path fill={props.circleColor} d="M 176 884  a 500 500, 0, 1, 1, 1 1 z" />
      <path
        fill={props.personColor}
        fillOpacity={props.personOpacity}
        d="M 610 306  a 113 113, 0, 1, 0, 1 1 z
           M530 560 c -76 0 -228 38 -228 114 v 55 h 456 v -55 c 0 -76 -152 -114 -228 -114 z"
      />
    </svg>
  );
};
