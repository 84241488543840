const SET_TARGET_INPUT = 'SET_TARGET_INPUT';
const SET_KEYBOARD_VISIBILITY = 'SET_KEYBOARD_VISIBILITY';

const initialKeyboardState = {
  targetInput: null,
  isKeyboardVisible: false
};

export const keyboardReducer = (keyboardState = initialKeyboardState, action = null) => {
  switch (action && action.type) {
    case SET_KEYBOARD_VISIBILITY:
      return {
        ...keyboardState,
        isKeyboardVisible: action.payload.isVisible
      };

    case SET_TARGET_INPUT:
      return {
        ...keyboardState,
        targetInput: action.payload.targetInput
      };

    default:
      return keyboardState;
  }
};

export const keyboardActions = {
  setKeyboardVisibility: (isVisible) => ({ type: SET_KEYBOARD_VISIBILITY, payload: { isVisible: isVisible } }),
  setTargetInput: (targetInput) => ({ type: SET_TARGET_INPUT, payload: { targetInput: targetInput } })
};

export const keyboardSelectors = {
  getTargetInput: (state) => state.keyboard.targetInput,
  isKeyboardVisible: (state) => state.keyboard.isKeyboardVisible,
  showAlt: (state) => false,
  showShift: (state) => true
};
