export const SECOND = 1000;
export const MINUTE = 60 * SECOND;
export const HOUR = 60 * MINUTE;

export const getMinutesDiff = (date1, date2) => {
  return Math.ceil(Math.abs(date1 - date2) / MINUTE);
};

export const getTime = (date, locale) => {
  return date.toLocaleTimeString(locale, { hour: '2-digit', minute: '2-digit' });
};

export const getDate = (date, locale) => {
  return date.toLocaleDateString(locale, { month: 'numeric', day: '2-digit' });
};

export const getEndOfDay = () => {
  const end = new Date();
  end.setHours(23, 59, 59, 999);
  return end;
};
