import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { userActions, userSelectors } from '@rosetta/gaia-data';
import { MouseCatcher } from 'modules/ui/components/MouseCatcher';
import { getConfig } from 'modules/app/configs/config-utils';

/**
 * this is just a place to go while the user is logging out
 *  because many pages rely on users and will try to draw between
 *  the time logout() is called and the NotAuthorized route is routed to
 */
class LogoutPageBase extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { loggedOut: false };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (!prevState.loggedOut) {
      nextProps.logout();
      return { loggedOut: true };
    }
    if (!nextProps.isLoggedIn) {
      //logout page is hosted on LaunchPad
      const uri = getConfig().logoutUrl;
      window.location = uri;
    }
    return null;
  }

  render() {
    return <MouseCatcher />;
  }
}

const mapStateToProps = (state) => {
  return {
    isLoggedIn: userSelectors.isLoggedIn(state)
  };
};
const mappedActions = {
  logout: userActions.logout
};

export const LogoutPage = connect(
  mapStateToProps,
  mappedActions
)(LogoutPageBase);
