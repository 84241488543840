import { userSelectors } from '@rosetta/gaia-data';
import { logger } from 'modules/app/utils/logger';

export const START_SRE_INITIALIZATION = 'START_SRE_INITIALIZATION';
export const START_CALIBRATION = 'START_CALIBRATION';
export const START_LISTEN_FOR_PHRASES = 'START_LISTEN_FOR_PHRASES';
export const SAVE_LISTEN_FOR_PHRASES_RESULT = 'SAVE_LISTEN_FOR_PHRASES_RESULT';
export const SRE_MIC_STORAGE_ID = 'SRE_MIC_STORAGE_ID';
export const SET_SRE_ENABLED = 'SET_SRE_ENABLED';

export const initialState = {
  recording: false,
  result: null,
  results: [],
  successfulCalibration: null
};

export const speechReducer = (state = initialState, action = undefined) => {
  switch (action && action.type) {
    case 'SRE_CALIBRATE_COMPLETE':
      return {
        ...state,
        successfulCalibration: true
      };
    case START_SRE_INITIALIZATION:
      return {
        ...state,
        ready: false,
        result: null,
        results: []
      };
    case START_CALIBRATION:
      return {
        ...state,
        ready: false,
        successfulCalibration: false
      };
    case START_LISTEN_FOR_PHRASES:
      return {
        ...state,
        ready: false,
        result: null
      };
    case SAVE_LISTEN_FOR_PHRASES_RESULT:
      return {
        ...state,
        result: action.payload,
        results: [...state.results, action.payload]
      };
    case SET_SRE_ENABLED:
      return {
        ...state,
        sreEnabled: action.payload
      };
    default:
      return state;
  }
};

export const speechActions = {
  startSREInitialization: (calibrate = true) => ({
    type: START_SRE_INITIALIZATION,
    payload: {
      calibrate
    }
  }),
  startListenForPhrases: (speakables, prompt) => ({
    type: START_LISTEN_FOR_PHRASES,
    payload: {
      speakables,
      prompt
    }
  }),
  saveListenForPhrasesResult: (result) => ({
    type: SAVE_LISTEN_FOR_PHRASES_RESULT,
    payload: result
  }),
  // SET_SRE_ENABLED is only used to trigger renders. The getSreEnabled selector pulls from sessionStorage.
  setSreEnabled: (sreEnabled) => ({
    type: SET_SRE_ENABLED,
    payload: sreEnabled
  })
};

export const speechSelectors = {
  successfulCalibration: (state) => state.speech.successfulCalibration,
  lastRecordedResult: (state) => {
    if (state.speech.results.length) {
      return state.speech.results[state.speech.results.length - 1];
    }
    return null;
  },
  getCurrentProductLanguage: (state) => {
    let productLanguage = userSelectors.getLearningLanguage(state);
    if (!productLanguage) {
      console.error('no product language available');
      productLanguage = 'en-US';
    }
    return productLanguage;
  },
  getSpeechVoiceType: (state) => {
    let voiceType = userSelectors.getUserVoiceType(state);
    if (!voiceType) {
      logger.error('no voice type available');
      voiceType = 'male';
    }
    return voiceType;
  },
  getCurrentMicrophone: (state) => {
    let storedMic = localStorage.getItem(SRE_MIC_STORAGE_ID);
    if (!storedMic) {
      storedMic = 'default';
    }
    return storedMic;
  },
  getSrePromptVolume: (state) => {
    let storedVolume = localStorage.getItem('srePromptVolume');
    if (!storedVolume) {
      storedVolume = '1';
    }
    return storedVolume;
  },
  getSreEnabled: (state) => {
    return !sessionStorage.getItem('sreDisabled');
  }
};
