import React from 'react';
import { Colors } from 'modules/app/style/colors';

export const ContentFileIcon = ({
  circleColor = Colors.VeryLightBlueGrey,
  iconColor = Colors.PrimaryDark,
  textColor = Colors.Secondary
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      preserveAspectRatio="none"
      viewBox="0 0 1060 1060"
    >
      <circle cx={530} cy={530} r={530} fill={circleColor} />
      <path
        fill={iconColor}
        d="M 574 281
          h -220
          c -24 0 -44 20 -44 44
          v 286
          a 22 22 0 0 0 22 22
          a 22 22 0 0 0 22 -22
          v -264
          a 22 22 0 0 1 22 -22
          h 220
          a 22 22 0 0 0 22 -22
          a 22 22 0 0 0 -22 -22
          z

          m 34 96
          l 106 106
          a 44 44 0 0 1 13 31
          v 211
          c 0 24 -20 44 -44 44
          h -240
          c -24 0 -44 -20 -44 -44
          l 0 -318
          c 0 -24 20 -44 44 -44
          h 126
          a 44 44 0 0 1 44 22
          z"
      />
      <path fill={circleColor} d="M 699 513 h -99 a 22 22 0 0 1 -22 -22 v -99 l 121 121 z" />
      <path fill={textColor} d="M 548 562 h 26 v 92 h -26 z m 0 105 h 26 v 26 h -26 z" />
    </svg>
  );
};
