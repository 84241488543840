import { all, fork } from 'redux-saga/effects';
import { sreCreateSaga } from '@rosetta/react-sre';
import { appSagas } from 'modules/app/redux/app-sagas';
import {
  abSagas,
  brazeSagas,
  courseSagas,
  eventsSagas,
  sequenceSagas,
  tutoringSagas,
  userSagas
} from '@rosetta/gaia-data';
import { progressSagas, stepStatusSagas } from '@rosetta/gaia-progress';
import { mediaSagas } from '@rosetta/gaia-media';
import { speechSagas } from 'modules/speech/redux/speech-sagas';
import { speechSelectors } from './modules/speech/redux/speech-reducer';

const sreCancelableActions = ['EXIT_APP'];
const sreAppSelectors = {
  getCurrentProductLanguage: speechSelectors.getCurrentProductLanguage,
  getSpeechVoiceType: speechSelectors.getSpeechVoiceType,
  getCurrentMicrophone: speechSelectors.getCurrentMicrophone,
  getSoundVolume: speechSelectors.getSrePromptVolume
};

export function* rootSaga() {
  yield all([
    fork(abSagas),
    fork(appSagas),
    fork(brazeSagas),
    fork(courseSagas),
    fork(eventsSagas),
    fork(mediaSagas),
    fork(progressSagas),
    fork(stepStatusSagas),
    fork(sequenceSagas),
    fork(speechSagas),
    fork(tutoringSagas),
    fork(userSagas),
    fork(sreCreateSaga(sreAppSelectors, sreCancelableActions))
  ]);
}
