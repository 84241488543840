import gql from 'graphql-tag';

const CacheDetails = gql`
  fragment CacheDetails on Cache {
    id
    keys
    hits
    misses
  }
`;

export const CacheQuery = gql`
  query getCacheStats {
    caches {
      ...CacheDetails
    }
  }
  ${CacheDetails}
`;

export const CacheMutation = gql`
  mutation flushCache($id: String!) {
    flushCache(id: $id) {
      ...CacheDetails
    }
  }
  ${CacheDetails}
`;
