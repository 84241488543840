import { createStore, applyMiddleware } from 'redux';
import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import { routerMiddleware } from 'connected-react-router';

import { isProduction, isUnderCI } from './configs/config-utils';
import { rootReducer } from '../../root-reducer';
import { rootSaga } from '../../root-saga';

//Logger will ignore actions of these types
const LOGGER_IGNORE_ACTIONS = [
  'APOLLO_QUERY_INIT',
  'APOLLO_QUERY_REQUEST',
  'APOLLO_QUERY_RESULT',
  'SET_CURRENT_TOOLTIP',
  'SRE_AUDIO_LEVEL',
  'SRE_CALIBRATE_COMPLETE',
  'SRE_CLOSE_CALIBRATE_WIN',
  'SRE_HYPOTHESIS',
  'SRE_LOAD_STATUS',
  'SRE_MIC_CHOSEN',
  'SRE_MIC_LIST',
  'SRE_SPEECH_MODEL_LOAD_COMPLETE',
  'TRANSLATE_KEY',
  'INIT_USER',
  'USER_INITIALIZED'
];
//Logger will collapse console grouping for these actions
const LOGGER_COLLAPSED_ACTIONS = ['REFRESH_TOKEN', 'TOKEN_RECEIVED', 'SAVE_SEQUENCE', 'SELECT_COURSE'];

const DEV_IGNORE_ACTIONS = ['SRE_AUDIO_LEVEL', 'SRE_HYPOTHESIS', 'SRE_LOAD_STATUS', 'TRANSLATE_KEY'];

// we needed some place to publicly make the redux store available for careful users
let publicStore;
export const setReduxStore = (reduxStore) => {
  if (publicStore) {
    console.error('setting public store twice');
  }
  publicStore = reduxStore;
};
export const getReduxStore = () => {
  return publicStore;
};

const configureStore = (history) => {
  const routeHistoryMiddleware = routerMiddleware(history);
  const sagaMiddleware = createSagaMiddleware();

  let middleware = [routeHistoryMiddleware, sagaMiddleware];

  if (!isProduction() && !isUnderCI()) {
    const COLLAPSE_ALL = true;
    const reduxLoggerOptions = {
      predicate: (getState, action) => !LOGGER_IGNORE_ACTIONS.includes(action.type),
      collapsed: COLLAPSE_ALL
        ? (getState, action, logEntry) => !logEntry.error
        : (getState, action) => LOGGER_COLLAPSED_ACTIONS.includes(action.type)
    };
    const reduxLogger = createLogger({ ...reduxLoggerOptions });
    middleware.push(reduxLogger);
  }

  const createStoreWithMiddleware = applyMiddleware(...middleware)(createStore);

  let store = null;
  if (!isProduction() && !isUnderCI()) {
    // Filter out actions that happen a lot and in quick succession
    // These define the actions which show in the redux tool
    const devToolsOptions = {
      maxAge: 100,
      actionsBlacklist: DEV_IGNORE_ACTIONS
    };

    // Set up store with redux dev tools in dev mode.
    store = createStoreWithMiddleware(
      rootReducer(history),
      window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(devToolsOptions)
    );
  } else {
    store = createStoreWithMiddleware(rootReducer(history));
  }

  return {
    ...store,
    // For more on saga error logging see: https://jira.trstone.com/wiki/display/REACT/Error+Handling
    runSaga: sagaMiddleware.run(rootSaga)
  };
};

export default configureStore;
