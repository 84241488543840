import { userSelectors } from '@rosetta/gaia-data';
import { registerDevFunction } from 'modules/dev/consoleCommands';
import { RemoveAllUserProgress } from '@rosetta/gaia-progress';

registerDevFunction('clearProgress', (context) => () => {
  const { store, apolloClient } = context;
  const userId = userSelectors.getUserId(store.getState());
  apolloClient.mutate({
    mutation: RemoveAllUserProgress,
    variables: { userId },
    refetchQueries: ['getProgress']
  });
});
