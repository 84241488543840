import styled from '@emotion/styled';
import React, { PureComponent } from 'react';
import { compose } from 'react-apollo';
import { connect } from 'react-redux';
import { tutoringSelectors, userSelectors } from '@rosetta/gaia-data';
import { TOP_NAVBAR_HEIGHT } from 'modules/app/fragments/TopNavbar';
import { Colors } from 'modules/app/style/colors';
import { getDate, getEndOfDay, getMinutesDiff, getTime, HOUR } from 'modules/app/utils/time-utils';
import { withLionTranslate } from 'modules/localization/containers/withLionTranslate';
import { HeadsetInCircleIcon } from 'modules/ui/assets/icons/HeadsetInCircleIcon';
import { withHover } from 'modules/ui/containers/withHover';
import { stopPollingActiveStatus } from 'modules/app/redux/app-sagas';

const SESSION_TIME_GREY = '#646464';
const SESSION_TIME_RED = '#eb5757';
const SESSION_TIME_ORANGE = '#f2994a';

const TutoringWidgetDiv = styled.div((props) => {
  const style = {
    position: 'relative',
    width: 220,
    height: TOP_NAVBAR_HEIGHT,
    marginRight: 16,
    cursor: 'pointer'
  };
  if (props.disabled) {
    style.pointerEvents = 'none';
    style.opacity = 0.5;
  }
  return style;
});

const IconPositioner = styled.div({
  position: 'absolute',
  top: 12,
  left: 6,
  width: 35,
  height: 35
});

const TextButtonsContainer = styled.div({
  position: 'absolute',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  top: 0,
  bottom: 0,
  left: 50,
  right: 3
});

const TutoringTitleText = styled.div((props) => {
  const style = {
    fontSize: 15,
    lineHeight: '18px',
    color: Colors.DarkGreyBlue,
    whiteSpace: 'nowrap',
    height: 18,
    textAlign: 'left'
  };
  if (props.hovering) {
    if (props.active) {
      style.color = Colors.ActiveBlue;
    } else {
      style.color = Colors.SelectedBlue;
    }
  }
  return style;
});

const TutoringSessionText = styled.div((props) => {
  const style = {
    fontSize: 14,
    lineHeight: '20px',
    color: props.textColor,
    whiteSpace: 'nowrap',
    height: 20,
    textAlign: 'left'
  };
  if (props.hovering) {
    if (props.active) {
      style.color = Colors.ActiveBlue;
    } else {
      style.color = Colors.SelectedBlue;
    }
  }
  return style;
});

//***************************************************************************************
class TutoringWidgetBase extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      remainingMinutes: null
    };
    this.countdownCallback = null;
    this.formRef = React.createRef();
  }

  componentDidMount() {
    this.resetRemainingMinutes();
  }

  componentWillUnmount() {
    if (this.countdownCallback) {
      clearTimeout(this.countdownCallback);
      this.countdownCallback = null;
    }
  }

  componentDidUpdate() {
    this.resetRemainingMinutes();
  }

  handleLaunchClick = () => {
    if (this.formRef.current) {
      stopPollingActiveStatus();
      this.formRef.current.submit();
    }
  };

  // set up callbacks reset state and thus render if we need to
  //  update the "Starts in N mins" display
  resetRemainingMinutes = () => {
    if (this.countdownCallback) {
      clearTimeout(this.countdownCallback);
      this.countdownCallback = null;
    }
    if (this.props.nextSessionTime) {
      const nowTime = Date.now();
      const sessionTime = new Date(this.props.nextSessionTime);
      const remainingMinutes = getMinutesDiff(nowTime, sessionTime);

      if (remainingMinutes > 0 && remainingMinutes < 60) {
        this.countdownCallback = setTimeout(() => {
          this.setState({ remainingMinutes: remainingMinutes });
        }, 60000);
      }
    }
  };

  render() {
    let sessionDisp;
    // only show the session time display if there is a next session scheduled
    if (this.props.nextSessionTime) {
      const sessionTime = new Date(this.props.nextSessionTime);
      const nowTime = Date.now();
      let sessionColor;
      let sessionText;

      if (sessionTime > getEndOfDay()) {
        // "Next: {shortDate}, {sessionTime}"
        const shortDateStr = getDate(sessionTime, this.props.locale);
        sessionText = this.props.lionTranslate('_tutoring_next', {
          shortDate: shortDateStr,
          sessionTime: getTime(sessionTime, this.props.locale)
        });
        sessionColor = SESSION_TIME_GREY;
      } else if (sessionTime < nowTime) {
        // "Session started"
        sessionText = this.props.lionTranslate('_tutoring_started');
        sessionColor = SESSION_TIME_RED;
      } else if (sessionTime < nowTime + HOUR) {
        // "Starts in {minutes} mins"
        sessionText = this.props.lionTranslate('_tutoring_starts', { minutes: getMinutesDiff(nowTime, sessionTime) });
        sessionColor = SESSION_TIME_ORANGE;
      } else {
        // "Today at {sessionTime}"
        sessionText = this.props.lionTranslate('_tutoring_today', {
          sessionTime: getTime(sessionTime, this.props.locale)
        });
        sessionColor = SESSION_TIME_GREY;
      }
      sessionDisp = (
        <TutoringSessionText textColor={sessionColor} hovering={this.props.hovering} active={this.props.active}>
          {sessionText}
        </TutoringSessionText>
      );
    }

    let iconCircleColor = Colors.DarkGreyBlue;
    if (this.props.hovering) {
      if (this.props.active) {
        iconCircleColor = Colors.ActiveBlue;
      } else {
        iconCircleColor = Colors.SelectedBlue;
      }
    }

    let launchForm;
    if (this.props.launchInfo) {
      launchForm = (
        <form ref={this.formRef} method="POST" action={this.props.launchInfo.url}>
          <input type="hidden" name="token" value={this.props.launchInfo.token} />
          <input type="hidden" name="locale" value={this.props.locale} />
        </form>
      );
    }

    return (
      <TutoringWidgetDiv data-qa="TutorWidget" disabled={!this.props.launchInfo} onClick={this.handleLaunchClick}>
        <IconPositioner>
          <HeadsetInCircleIcon circleColor={iconCircleColor} iconColor={Colors.Light} />
        </IconPositioner>
        <TextButtonsContainer>
          <TutoringTitleText hovering={this.props.hovering} active={this.props.active}>
            {this.props.lionTranslate('_tutoring_title')}
          </TutoringTitleText>
          {sessionDisp}
        </TextButtonsContainer>
        {launchForm}
      </TutoringWidgetDiv>
    );
  }
}

const mapStateToProps = (state) => ({
  //***** dev only set to what you need
  // nextSessionTime:  Date.now() - (HOUR * .8),
  // nextSessionTime:  "Thu Jun 07 2019 04:00:00 GMT+0000 (Coordinated Universal Time)",     //***** dev only set to what you need

  nextSessionTime: tutoringSelectors.nextSessionTime(state),
  launchInfo: tutoringSelectors.tutoringSchedulerLaunchInfo(state),
  locale: userSelectors.getUILanguage(state)
});

export const TutoringWidget = compose(
  connect(mapStateToProps),
  withLionTranslate,
  withHover
)(TutoringWidgetBase);
