import { connect } from 'react-redux';
import { lionSelectors } from '@rosetta/react-lion';
import { getReduxStore } from 'modules/app/store';

const lionTranslateStatic = (key, replacements) => {
  return lionSelectors.getLionTranslatedString(getReduxStore().getState(), key, replacements);
};

const mapStateToProps = (state) => ({
  lionAppResourcesLoaded: lionSelectors.getLionAppResourcesLoaded(state),
  lionTranslate: lionTranslateStatic
});

export const withLionTranslate = connect(mapStateToProps);
