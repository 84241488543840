/*
  Set of utility functions the automation tests can run.

  They will be called with a context that includes:
    - store
    - (more to come)

  So you can do things like context.store.blah

  Copied from https://stash.trstone.com/projects/FE/repos/zoom/browse/src/course/automation-utils.js

  See usage at modules/speech/init.js

  Gaia note: We might want to bind apollo client as well.

  Things should do:

  1. Add/remove course
  2. Refetch course from GCS (Clear cache)
  3. Mark an activity step or whole activity correct?
  4. Clear progress

 */

const context = {};

window.gaiaDev = {};

export function registerDevFunction(key, funcCreator) {
  //window.gaiaDev[key] = () => func(context);
  window.gaiaDev[key] = funcCreator(context);
}

export function addToDevContext(key, value) {
  context[key] = value;
}
