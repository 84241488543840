import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import styled from '@emotion/styled';
import { lionSelectors } from '@rosetta/react-lion';
import { courseSelectors } from '@rosetta/gaia-data';
import { ErrorBoundary } from 'modules/app/fragments/ErrorBoundary';
// import { FeedbackWidget } from 'modules/app/fragments/FeedbackWidget';
import { appActions, appSelectors } from 'modules/app/redux/app-reducer';
import { Modals } from 'modules/speech/components/Modals';
import { MouseCatcher } from 'modules/ui/components/MouseCatcher';
import { APP_MIN_WIDTH } from '../constants';
import { areThereOtherActiveInstances } from '../utils/single-instance-utils';
import { isMobile } from '../utils/app-store-utils';
import { MultipleInstanceModal } from '../fragments/MultipleInstanceModal';

const MOBILE_SUPPORTED_ROUTES = ['/mobile', '/assessments', '/admin'];

const AppDiv = styled.div(({ isAssessment }) => {
  const style = {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0
  };
  if (!isAssessment) {
    style.minWidth = APP_MIN_WIDTH;
    style.minHeight = 768;
  }
  return style;
});

//***************************************************************************************
class App extends PureComponent {
  constructor(props) {
    super(props);
    props.initializeApp(props.location.search);
    this.checkedForOtherInstances = false;
  }

  componentDidMount() {
    const { history, location } = this.props;
    const activeRoute = `/${location.pathname.split('/')[1]}`;
    const mobileIsSupported = MOBILE_SUPPORTED_ROUTES.find((r) => activeRoute === r);
    if (isMobile() && !mobileIsSupported) {
      history.replace('/mobile');
    }
  }

  render() {
    if (!this.props.appInitialized) {
      return <MouseCatcher />;
    }

    if (this.props.location.pathname === '/mobile') {
      return this.props.children;
    }

    let multipleInstanceModal;
    if (!this.checkedForOtherInstances && this.props.lionAppResourcesLoaded) {
      this.checkedForOtherInstances = true;
      if (areThereOtherActiveInstances()) {
        multipleInstanceModal = <MultipleInstanceModal />;
      }
    }

    // Reinstate this if we'd like user feedback again, and if so,
    // be sure to uncomment all associated e2e scripts in steps.js and multipleChoice.js
    // const feedbackWidget = !this.props.isAssessment && <FeedbackWidget />;
    const feedbackWidget = null;

    return (
      <AppDiv isAssessment={this.props.isAssessment}>
        <ErrorBoundary>
          <Modals />
          {this.props.children}
          {feedbackWidget}
          {multipleInstanceModal}
          {this.props.currentTooltip}
        </ErrorBoundary>
      </AppDiv>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    appInitialized: appSelectors.isAppInitialized(state),
    lionAppResourcesLoaded: lionSelectors.getLionAppResourcesLoaded(state),
    currentTooltip: appSelectors.currentTooltip(state),
    isAssessment: courseSelectors.getIsAssessment(state)
  };
};

let mappedActions = {
  initializeApp: appActions.initializeApp
};

export const AppPage = withRouter(
  connect(
    mapStateToProps,
    mappedActions
  )(App)
);
