import styled from '@emotion/styled';
import { lionSelectors } from '@rosetta/react-lion';
import React, { PureComponent } from 'react';
import { compose } from 'react-apollo';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { userSelectors } from '@rosetta/gaia-data';
import { isDevelopment } from 'modules/app/configs/config-utils';
import { UserMenu } from 'modules/app/fragments/UserMenu';
import { userMenuStyle } from 'modules/app/fragments/UserMenuStyle';
import { Colors } from 'modules/app/style/colors';
import { withLionTranslate } from 'modules/localization/containers/withLionTranslate';
import { TutoringWidget } from 'modules/tutoring/components/TutoringWidget';
import logoImg from 'modules/ui/assets/images/rosetta_stone_logo.png';

export const TOP_NAVBAR_HEIGHT = 60;

const TopNavbarDiv = styled.div({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  height: TOP_NAVBAR_HEIGHT,
  backgroundColor: '#fff'
});

const TopNavbarRight = styled.div({
  position: 'absolute',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'flex-start',
  top: 0,
  bottom: 0,
  right: 8
});

const Logo = styled.div((props) => {
  const style = {
    display: 'inline-block',
    marginLeft: 20,
    marginRight: 8,
    height: 60,
    width: 135,
    verticalAlign: 'top',
    backgroundImage: 'url(' + logoImg + ')',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '0px 17px',
    backgroundSize: 'auto 24px',
    cursor: 'pointer'
  };
  if (props.selected) {
    style.cursor = 'default';
    style.pointerEvents = 'none';
  }
  return style;
});

const TopNavbarItem = styled.div((props) => {
  const style = {
    display: 'inline-block',
    marginTop: 17,
    marginLeft: 20,
    marginRight: 8,
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 3,
    height: 30,
    verticalAlign: 'top',
    fontSize: '16px',
    fontWeight: 'normal',
    color: Colors.Dark,
    ':hover': {
      color: Colors.NavbarItemHover,
      '& div': {
        backgroundColor: Colors.NavbarItemHover
      }
    },
    cursor: 'pointer'
  };
  if (props.selected) {
    style.color = Colors.SelectedBlue;
    style['& div'] = {
      backgroundColor: Colors.SelectedBlue
    };
  }
  if (props.disabled) {
    style.cursor = 'default';
    style.pointerEvents = 'none';
  }
  return style;
});

const userMenuItems = [
  {
    label: '_left_nav_profile',
    value: 'profile'
  },
  {
    label: '_top_nav_logout',
    value: 'logout'
  }
];

const noOp = () => {};

const homePath = '/';
const adminPath = '/admin';

class TopNavBarBase extends PureComponent {
  handleClickedHome = () => {
    this.props.history.push(homePath);
  };

  handleClickedAdmin = () => {
    this.props.history.push(adminPath);
  };

  render() {
    if (!this.props.isLionReady) {
      return null;
    }

    let adminLink = null;
    if (isDevelopment()) {
      adminLink = (
        <TopNavbarItem
          data-qa="adminPageLink"
          selected={this.props.location.pathname === adminPath}
          disabled={this.props.location.pathname === adminPath}
          onClick={this.handleClickedAdmin}
        >
          Admin
        </TopNavbarItem>
      );
    }

    let tutoringWidget;
    if (this.props.isTutoringAvailable) {
      tutoringWidget = <TutoringWidget />;
    }

    return (
      <TopNavbarDiv>
        <Logo onClick={this.handleClickedHome} selected={this.props.location.pathname === homePath} />

        <TopNavbarItem
          selected={this.props.location.pathname === homePath}
          disabled={this.props.location.pathname === homePath}
          onClick={this.handleClickedHome}
        >
          {this.props.lionTranslate('_left_nav_courses')}
        </TopNavbarItem>

        {adminLink}

        <TopNavbarRight>
          {tutoringWidget}

          <UserMenu
            itemsList={userMenuItems}
            itemDisplayProperty="label"
            itemKeyProperty="value"
            onSelectionHandler={noOp}
            selectedKey={'nothing'}
            menuTabIndex={-1}
            menuStyle={userMenuStyle}
          />
        </TopNavbarRight>
      </TopNavbarDiv>
    );
  }
}

const mapStateToProps = (state) => ({
  isLionReady: lionSelectors.getLionAppResourcesLoaded(state),
  isTutoringAvailable: userSelectors.isTutoringAvailable(state)
});

export const TopNavbarFragment = compose(
  connect(mapStateToProps),
  withLionTranslate,
  withRouter
)(TopNavBarBase);
