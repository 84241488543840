import { getDevConfig } from './dev';
import { getLocalConfig } from './local';
import { getLocalDockerConfig } from './local_docker';
import { getS3Config } from './s3';
import { dependencies } from '../../../../package.json';

export const getConfig = () => {
  const host = window.location.host;
  let outConfig;
  if (/resources/.test(host)) {
    outConfig = getDevConfig(); //TODO: Not used anymore, should we remove?
  } else if (/localhost:918/.test(host)) {
    outConfig = getLocalDockerConfig();
  } else if (/learn/.test(host)) {
    outConfig = getS3Config();
  } else {
    outConfig = getLocalConfig();
  }
  outConfig.sreVersion = dependencies['@rosetta/js-sre'] || 'n/a';
  return outConfig;
};

export const isLocalhost = () => {
  return getConfig().localhost === true;
};

export const isDevelopment = () => {
  return !getConfig().prod;
};

export function isProduction() {
  return getConfig().prod === true;
}

export function isUnderCI() {
  return !!((process.env.REACT_APP_RS_ENVIRONMENT && process.env.REACT_APP_RS_ENVIRONMENT === 'ci') || process.env.CI);
}

// Determine if code is currently running in tests BUT ONLY WORKS locally!!!
export function isTestEnv() {
  return !!process.env.REACT_APP_TESTING;
}
