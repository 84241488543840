import { registerDevFunction } from 'modules/dev/consoleCommands';
import { flushCache } from './cache-services';

registerDevFunction('refreshCourses', () => () => {
  flushCache('courses');
});

registerDevFunction('flushCache', () => (id) => {
  flushCache(id);
});
