import React from 'react';
import { connect } from 'react-redux';

import { appActions, appSelectors } from '../redux/app-reducer';
import { ErrorPage } from '../pages/ErrorPage';

/**
 * ErrorBoundary.jsx
 * allows the ability to catch errors of and provide
 * a fallback UI for any component it is wrapped around.
 * Problems can be intercepted and hopefully resolved
 * without the whole application crashing.
 *
 * Note: In development, the HMR will only allow the boundary to be shown
 * for a brief moment before the default error message screen is rendered.
 *
 * More info at:
 * https://facebook.github.io/react/blog/2017/07/26/error-handling-in-react-16.html
 */

export class ErrorBoundaryBase extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false
    };
  }

  componentDidCatch(error, info) {
    this.props.handleError({
      message: error.message,
      params: { reactStack: error.stack, reactErrorInfo: info }
    });
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError || this.props.errorId) {
      return <ErrorPage />;
    }

    return this.props.children;
  }
}

const mapStateToProps = (state) => ({
  errorId: appSelectors.errorId(state)
});
const mappedActions = {
  handleError: appActions.handleError
};

export const ErrorBoundary = connect(
  mapStateToProps,
  mappedActions
)(ErrorBoundaryBase);
