import { logger } from 'modules/app/utils/logger';
import { registerDevFunction } from 'modules/dev/consoleCommands';
import { userSelectors } from '@rosetta/gaia-data';
import { AssessmentStartMutation } from './apollo/assessment-graphql';

registerDevFunction(
  'startAssessment',
  (context) => (
    assessmentName,
    formNumber,
    version = 1,
    language = 'en-US',
    callbackUrl = '',
    redirectUrl = '',
    restart = true
  ) => {
    const { store, apolloClient } = context;
    const userId = userSelectors.getUserId(store.getState());

    apolloClient
      .mutate({
        mutation: AssessmentStartMutation,
        variables: {
          message: {
            userId,
            assessmentName,
            formNumber,
            version,
            language,
            redirectUrl,
            callbackUrl,
            restart
          }
        }
      })
      .then((res) => (window.location = res.data.assessmentStart.startUrl))
      .catch((e) => logger.error('Error while starting assessment: ', e));
  }
);
