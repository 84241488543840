export const getDevConfig = () => ({
  serverUrl: 'https://gaia-server-dev.dev.rosettastone.com',
  loginUrl: !!process.env.REACT_APP_USE_DEV_LOGIN
    ? '/dev_only_login'
    : 'https://login-dev.dev.rosettastone.com/#/login',
  logoutUrl: 'https://login-dev.dev.rosettastone.com/#/logout',
  redirectParam: '?uri=',
  profileUrl: 'https://login-dev.dev.rosettastone.com/#/preferences',
  errbitUrl: 'https://errbit.dev.rosettastone.com',
  errbitApi: '5bd9fe0c5314026894787cf83ffb1c66',
  brazeApiKey: 'a6500576-763f-4b08-804f-9c1a1d7f9031',
  amplitudeKey: 'noMoreAmplitude',
  env: 'dev',
  appName: 'Fluency Builder'
});
