import React from 'react';

export const CloseXIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      preserveAspectRatio="none"
      viewBox="0 0 1060 1060"
    >
      <path
        fill={props.xColor}
        d="M1040 940
          l -103 103
          -409 -409
          -409 409
          -103 -103
          409 -409
          -409 -409
          103-103
          409 409
          409-409
          103 103
          -409 409
          z"
      />
    </svg>
  );
};
